import React from 'react'
import { FiChevronLeft } from "react-icons/fi";

export default function Rules() {

    return (
        <div style={{ padding: '10px' }}>
            <FiChevronLeft size={40} onClick={() => window.history.back()}
                style={{ background: '#E22717', borderRadius: '50%', padding: '5px', cursor: 'pointer', color: 'white', marginBottom: '10px' }} />
            <p>
                NEO | Универсальная финансовая платформа <br />
                <br />
                Пользовательское соглашение <br />
                <br />
                1.   Регистрация и аккаунт: <br />
                - Для использования платформы необходимо зарегистрировать аккаунт.<br />
                - При регистрации вы обязуетесь предоставить достоверные и актуальные данные.<br />
                - Аккаунт на платформе является личным и не подлежит передаче третьим лицам.<br />

                2.   Конфиденциальность и безопасность:<br />
                - Мы обеспечиваем конфиденциальность ваших данных и обязуемся не передавать их третьим лицам.<br />
                - Пожалуйста, сохраняйте свои учетные данные в тайне и не делитесь ими с кем-либо еще.<br />

                3.   Использование платформы:<br />
                - Пользователи обязуются использовать NEO согласно его предназначению и не нарушать правила платформы.<br />
                - Мы оставляем за собой право ограничивать доступ к сервисам платформы в случае нарушения условий использования.<br />

                4.   Ответственность:<br />
                4.1. Мы несем ответственность за качество и предоставление услуг, описанных на платформе NEO.<br />
                4.2. Однако мы не несем ответственности за потери или убытки, понесенные пользователями в результате изменений курса криптовалюты NEO Coin после покупки товара или услуги через Платформу NEO.<br />
                4.3. На Платформе NEO нельзя продавать нелегальные товары, и мы оставляем за собой право блокировать доступ к таким товарам.<br />

                5.   Личный договор и качество товара:<br />
                - Покупка и продажа товаров через Платформу NEO осуществляется по личному договору обеих сторон.<br />
                - Мы не несем ответственности за качество товаров, купленных через Платформу NEO, однако будем стремиться предоставить максимально удовлетворительный опыт для наших пользователей.<br />

                6.   Комиссии:<br />
                - При использовании Платформы NEO могут взиматься комиссии за определенные услуги или транзакции.<br />
                - Эти комиссии могут обновляться, и пользователи обязуются ознакомиться с ними перед совершением операций.<br />

                7.   Изменения и обновления:<br />
                - Мы оставляем за собой право вносить изменения в пользовательское соглашение без предварительного уведомления.<br />
                - Пользователи обязуются регулярно проверять условия использования на наличие обновлений.<br />
                <br />
                Пользуясь Платформой NEO, вы соглашаетесь с этими условиями.<br />
                <br />
                Приятных вам покупок!
            </p>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import "./filter-market.css";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCity,
  changePricefrom,
  changePriceto,
  changeSort,
} from "../../../App/slice/market2/filter";

const cities = [
  { city: "Выберите город" },
  { city: "Бишкек" },
  { city: "Нурсултан" },
  { city: "Москва" },
  { city: "Алматы" },
  { city: "Астана" },
  { city: "Ташкент" },
  { city: "Баку" },
  { city: "Душанбе" },
  { city: "Тбилиси" },
  { city: "Ереван" },
  { city: "Киев" },
  { city: "Минск" },
  { city: "Кишинёв" },
  { city: "Рига" },
  { city: "Вильнюс" },
];

const sorts = [
  {
    name: "Самые новые",
    code: "newest",
  },
  {
    name: "Самые старые",
    code: "oldest",
  },
  {
    name: "Самые дешевые",
    code: "cheapest",
  },
  {
    name: "Самые дорогие",
    code: "expensive",
  },
];

const FilterMarket = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const [requests, setRequests] = useState({
    budget: [0, 0],
  });
  const dispatch = useDispatch();
  const { pricefrom, priceto, city, sort } = useSelector(
    (state) => state.filter
  );

  useEffect(() => {
    setRequests({ ...requests, budget: [pricefrom, priceto] });
  }, [pricefrom, priceto]);

  useEffect(() => {
    dispatch(changePricefrom(requests.budget[0]));
    dispatch(changePriceto(requests.budget[1]));
  }, [requests, dispatch]);

  const handleBudgetChange = (index, value) => {
    const newBudget = [...requests.budget];
    newBudget[index] = Number(value);
    setRequests({ ...requests, budget: newBudget });
  };

  function empty() {
    setRequests({ ...requests, budget: [0, 0] });
    dispatch(changePricefrom(""));
    dispatch(changePriceto(""));
    dispatch(changeCity(""));
    dispatch(changeSort(""));
    navigate('/market')
  }

  return (
    <div className="filter_market">
      <div className="head_market">
        <MdOutlineArrowBackIosNew
          onClick={() => navigate('/market')}
          color="var(--black)"
          size={24}
        />
        <h1>Фильтр и сортировка</h1>
      </div>
      <div className="filter_body">
        <div className="ranges">
          <h3>Ценовой диапазон</h3>
          <div className="values-container">
            <input
              className="value_div"
              type="number"
              value={requests.budget[0] === 0 ? "" : requests.budget[0]}
              onChange={(e) => handleBudgetChange(0, e.target.value)}
              placeholder="От"
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span> - </span>
            </div>
            <input
              className="value_div"
              type="number"
              value={requests.budget[1] === 0 ? "" : requests.budget[1]}
              onChange={(e) => handleBudgetChange(1, e.target.value)}
              placeholder="До"
            />
          </div>
        </div>
        <div className="input_box_air">
          <h3>Город</h3>
          <select
            className="select_market"
            value={city}
            onChange={(e) => dispatch(changeCity(e.target.value))}
            type="text"
            placeholder="Страна выдачи"
            name="citizen"
          >
            {cities.map((item) => (
              <option key={item.city} value={item.city}>
                {item.city}
              </option>
            ))}
          </select>
        </div>
        <div className="sort">
          <h3>Сортировка</h3>
          <div className="sort_block">
            {sorts.map((el, index) => (
              <div
                onClick={() => dispatch(changeSort(el.code))}
                key={index}
                className="sort_box"
              >
                <div className="toggle">
                  {sort === el.code && <div className="true"></div>}
                </div>
                <p> {el.name} </p>
              </div>
            ))}
          </div>
        </div>
        <div className="btns">
          <button
            onClick={() => {
              navigate(`/market`)
              localStorage.setItem('activeTab', 1);
            }}
            className="button_form"
          >
            Применить
          </button>
          <button onClick={empty} className="button_form empty">
            Сбросить
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterMarket;

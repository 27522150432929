import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './message_detail.css';
import { useSelector } from 'react-redux';
import LoadingAnimate from '../../UI-kit/loading';
import { IoCheckmarkOutline, IoCheckmarkDoneSharp } from 'react-icons/io5';
import { FiChevronLeft, FiSend } from 'react-icons/fi';
import { AiOutlineDelete } from "react-icons/ai";
import { api } from '../../Api';
import { IoCallOutline } from "react-icons/io5";
import { FiVideo } from "react-icons/fi";
import { CiFaceSmile } from "react-icons/ci";
import { HiOutlinePaperClip } from "react-icons/hi2";

export default function MessageDetails() {
    const { sender, receiver } = useParams();
    const [selectedFile, setSelectedFile] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [isMessageSent, setIsMessageSent] = useState(false);
    const [messagebox, setMessagebox] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const messageContainerRef = useRef(null);
    const user_info = localStorage.getItem('userID');
    const { message } = location.state || {};

    const { sender_profile = {}, receiver_profile = {} } = message || {};
    const { first_name: senderFirstName, last_name: senderLastName, profile_photo: senderPhoto } = sender_profile;
    const { first_name: receiverFirstName, last_name: receiverLastName, profile_photo: receiverPhoto } = receiver_profile;

    useEffect(() => {
        const fetchMessages = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/chat/get-messages/${sender}/${receiver}`);
                setMessagebox(response.data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMessages();
    }, [sender, receiver]);

    useEffect(() => {
        scrollToBottom();
    }, [messagebox]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log('file:', file);

        }
    }

    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        setIsMessageSent(true);
        try {
            await api.post('/chat/send-messages/', {
                user: user_info,
                sender: user_info,
                receiver: user_info === sender ? receiver : sender,
                message: newMessage,
            });
            setNewMessage('');

            setIsLoading(true);
            const response = await api.get(`/chat/get-messages/${sender}/${receiver}`);
            setMessagebox(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsMessageSent(false);
            setIsLoading(false);
        }
    };

    if (!message) {
        return <div>Загрузка...</div>;
    }

    return (
        <div className="message-details-page">
            <div className="message-details-header">
                <div className='message-details-profile'>
                    <FiChevronLeft color="#fff" onClick={() => navigate('/messages')} size={36} />
                    <img src={user_info === sender_profile.id ? receiverPhoto : senderPhoto} alt={`${user_info === sender_profile ? receiverFirstName : senderFirstName} ${user_info === sender_profile.id ? receiverLastName : senderLastName}`}
                        className="profile-photo" />
                    <h4>{`${user_info === sender_profile.id ? receiverFirstName : senderFirstName} ${user_info === sender_profile.id ? receiverLastName : senderLastName}`}</h4>
                </div>
                <div className='message-details-icons'>
                    <FiVideo color="#fff" size={24} />
                    <IoCallOutline color="#fff" size={24} />
                    <AiOutlineDelete color='fff' size={24} />
                </div>
            </div>
            <div ref={messageContainerRef} className="message-container">
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingAnimate />
                    </div>
                ) : messagebox.length === 0 ? (
                    <p className="empty-chat">Чат пуст</p>
                ) : (
                    messagebox.map((message, index) => (
                        <div key={index} className={`message-card ${user_info === message.sender ? 'sent' : 'received'}`}>
                            <div className="is-read-message-details">
                                {user_info === message.sender && (
                                    message.is_read ? <IoCheckmarkDoneSharp color="#1c6fab" /> : <IoCheckmarkOutline />
                                )}
                            </div>
                            <div className="message-content">
                                <div className="message-header">
                                    <span>{new Date(message.date).toLocaleString()}</span>
                                </div>
                                <p>{message.message}</p>
                                {message.ad && (
                                    <div className="ad-info">
                                        <img src={`https://neocoin.io${message.ad.img}`} alt={message.ad.title} className="ad-image" />
                                        <div className="ad-details">
                                            <h3>{message.ad.title}</h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>
            <form className="send-message-form" onSubmit={handleSendMessage}>
                <div className='send-message-input'>
                    <CiFaceSmile color="#9d9d9d" size={24} style={{ cursor: 'pointer' }} />
                    <input
                        type="text"
                        placeholder="Введите сообщение..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                    />
                </div>
                <div className='send-message'>
                    <div>
                        <div className="send-message-button"
                            onClick={handleButtonClick}
                        >
                            <HiOutlinePaperClip size={24} />
                        </div>
                        <input type="file"
                            id='fileInput'
                            style={{ display: 'none' }}
                            onChange={handleFileChange} />
                    </div>
                    <button
                        className={`send-message-button ${newMessage === '' ? 'disabled' : ''}`}
                        type="submit"
                        disabled={newMessage === '' || isMessageSent}
                    >
                        {isMessageSent ? <LoadingAnimate /> : <FiSend size={24} />}
                    </button>
                </div>
            </form>
        </div>
    );
}

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../pages/home-page/home-page";
import Payments from "../pages/payments/payments";
import Discount from "../pages/discount/discount";
import Market from "../pages/market/market";
import Profile from "../pages/profile/profile";
import WithDrawal from "../pages/asman-coin/with-drawal/with-drawal";
import Settings from "../pages/profile/settings/settings";
import Support from "../pages/profile/support/support";
import ReferalPage from "../pages/profile/referal-page/referal-page";
import ChangePassword from "../pages/auth/change-password/change-password";
import AdsDetail from "../pages/market/ads-detail/ads-detail";
import AdsPost from "../pages/market/ads-post/ads-post";
import FavoriteMarket from "../pages/market2/favorite-market/favorite-market";
// import MyPosts from "../pages/market/my-posts/my-posts";
// import FilterMarket from "../pages/market/filter-market/filter-market";
// import CategoryPage from "../pages/market/category-page/category-page";
// import AdsUpdate from "../pages/market/ads-update/ads-update";
import GlobalMarketPage from "../pages/market2/global-page/global-page";
import CategoryPage from "../pages/market2/category-page/category-page";
import AdsUpdate from "../pages/market2/ads-update/ads-update";
import MarketChat from "../pages/market2/market-chat/market-chat";
import FilterMarket from "../pages/market2/filter-market/filter-market";
import FilteredPage from "../pages/market2/filtered-page/category-page";
import NotificationPage from "../pages/notification/notification-page";
import NewsNotifications from "../pages/notification/news-notification";
import Mfa from "../pages/profile/settings/2mfa";
import SecretCode from "../pages/profile/settings/secret-code";
import Messages from "../pages/messages/messages";
import MessageDetails from "../pages/messages/message_detail";
import Birzha from "../pages/birzha/birzha";
import ChangeData from "../pages/auth/change-data/change-data";
import Expenses from "../pages/expenses/expenses";
import QrDetails from "../pages/home-page/components/QR code/qr-details/qr-details";
import BuyAsman from "../pages/asman-coin/buy-asman/buy-asman";
import VvodAsman from "../pages/asman-coin/vvod-asman/vvod-asman";
import BonusesAndStatuses from "../pages/bonuses-and-statuses/bonuses-and-statuses";
// import AdsPost from "../pages/market/tab-bar/ads-post";
import StoreHomePage from '../pages/neocoin-store/store-home-page/store-home-page';

export default function PageContent() {


  const PrivateRoute = ({ element }) => {
    const token = localStorage.getItem("accessToken");

    return token ? element : <Navigate to="/login" replace />;
  };

  return (
    <div>
      <Routes>
        <Route exact={true} path="/" element={<PrivateRoute element={<HomePage />} />} />
        <Route exact={true} path="/payments" element={<PrivateRoute element={<Payments />} />} />
        <Route exact={true} path="/discount" element={<PrivateRoute element={<Discount />} />} />
        <Route exact={true} path="/birzha" element={<PrivateRoute element={<Birzha />} />} />
        <Route exact={true} path="/profile" element={<PrivateRoute element={<Profile />} />} />
        {/* <Route exact={true} path="/market" element={<Market />} /> */}

        <Route exact={true} path="/with-drawal/:id" element={<PrivateRoute element={<WithDrawal />} />} />
        <Route exact={true} path="/drawal-with" element={<PrivateRoute element={<WithDrawal />} />} />
        <Route exact={true} path="/settings" element={<PrivateRoute element={<Settings />} />} />
        <Route exact={true} path="/support" element={<PrivateRoute element={<Support />} />} />
        <Route exact={true} path="/referal" element={<PrivateRoute element={<ReferalPage />} />} />
        <Route exact={true} path="/change-data" element={<PrivateRoute element={<ChangeData />} />} />
        <Route exact={true} path="/change-password" element={<ChangePassword />} />

        <Route exact={true} path="/market" element={<GlobalMarketPage />} />
        <Route exact={true} path="/market-chat" element={<PrivateRoute element={<MarketChat />} />} />
        <Route exact={true} path="/filter-market" element={<FilterMarket />} />
        <Route exact={true} path="/market-category/:id" element={<CategoryPage />} />
        <Route exact={true} path="/market-ads-update/:id" element={<AdsUpdate />} />
        <Route path="/category-market/:state" element={<FilteredPage />} />

        <Route path="/market-detail/:id" element={<AdsDetail />} />

        <Route exact={true} path="/natification-page" element={<PrivateRoute element={<NotificationPage />} />} />
        <Route exact={true} path="/news-notification" element={<PrivateRoute element={<NewsNotifications />} />} />

        <Route path="/mfa" element={<Mfa />} />
        <Route path="/secret-code" element={<SecretCode />} />

        <Route exact={true} path="/ads-post" element={<PrivateRoute element={<AdsPost />} />} />
        <Route exact={true} path="/favorite-market" element={<PrivateRoute element={<FavoriteMarket />} />} />
        <Route exact={true} path="/messages" element={<PrivateRoute element={<Messages />} />} />
        <Route exact={true} path="/expenses" element={<PrivateRoute element={<Expenses />} />} />
        <Route exact={true} path="/qr-details" element={<PrivateRoute element={<QrDetails />} />} />
        <Route exact={true} path="/buy-asman" element={<PrivateRoute element={<BuyAsman />} />} />
        <Route exact={true} path="/vvod-asman" element={<PrivateRoute element={<VvodAsman />} />} />
        <Route exact={true} path="/bonuses-and-statuses" element={<BonusesAndStatuses />} />
        <Route exact={true} path="/store" element={<StoreHomePage />} />

        {/* <Route path="/ads-apdate/:id" element={<AdsUpdate />} /> */}
        {/* <Route path="my-posts" element={<MyPosts />} /> */}
        {/* <Route path="filter-market/:page" element={<FilterMarket />} /> */}
        {/* <Route path="filter-market" element={<FilterMarket />} /> */}
        {/* <Route path="category-market/:state" element={<CategoryPage />} /> */}
      </Routes>
    </div>
  );
}

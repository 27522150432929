import React, { useState } from 'react'
import Header from '../header/headerStore'
import './store-home-page.scss'
import phone from '../../../views/neocoin.store/iPhone15.png'
import PartnersCard from '../components/partners/partners-card'
import Button from '../components/button/button'
import Advantages from '../components/advantages/advantages'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import map from '../../../views/neocoin.store/map.png'
import FeaturesCard from '../components/features/features-card'
import { HiOutlineLightBulb } from "react-icons/hi";
import { TbCoinBitcoin, TbWorld } from "react-icons/tb";
import divercity from '../../../views/neocoin.store/diversity.svg'
import { AiOutlineSafety } from "react-icons/ai";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";

export default function StoreHomePage() {

    const [active, setActive] = useState(1)

    function SampleNextArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                className='next-arrow'
                style={{
                    ...style,
                    position: 'absolute',
                    top: '295px',
                    left: '48%',
                    cursor: 'pointer',
                }}
                onClick={onClick}
            ><IoIosArrowDropright size={40} /></div>
        );
    }

    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                className='prev-arrow'
                style={{
                    ...style,
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '295px',
                    left: '46%',
                }}
                onClick={onClick}
            ><IoIosArrowDropleft size={40} /></div>
        );
    }

    const settings = {
        className: "slider variable-width",
        dots: false,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false
    };

    const setting = {
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />
    };

    return (
        <div className='store-home'>
            <Header />
            <div className='store-home-start'>
                <div className='store-home-start-content'>
                    <div>
                        <h1><span>Инвестиционная</span> <br /> Компания NEO</h1>
                        <div>
                            <p>Тысячи инвесторов доверяют нам свои сбережения, потому что мы единственный удобный метод преумножить свои средства и достичь своих целей на основе халяльных инвестиций.
                                <br /> <br />
                                Инвестиционная стратегия Мудараба - составленный в соответствии с исламскими финансами договор «доверительного управления» деньгами
                            </p>
                            <Button />
                        </div>
                    </div>
                    <div>
                        <img src={phone} alt="" />
                    </div>
                </div>
            </div>
            <h2>На что вы хотите копить?</h2>
            <div className='store-home-target'>
                <p>Цель поможет вам отслеживать прогресс</p>
                <div className='store-home-target-slider'>
                    <div>
                        <div>
                            <img src="https://s3-alpha-sig.figma.com/img/bfd6/faa3/e762852df88447efdbd76ebc4b08bc3b?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Mc6pqaP3WWtWcIwbO8VFcZOkK5KxaJ8DdQPYjvi2K8LyQoelk58NTIkONIqGBSP0UAS~OwEvMKqaul~ptOMUk0AJp5fqtSn78E0RyXtrD1~2w~40nwp5h00eoFsGO-4BDnLByJ~zSFbKi8q8FpZ2Vb7AMjlFXMtPBuUrv29m8xEmSMN-gsQIERIoHnBz3rDPulTYzNQHBo3KWOFhEX--uc0HL5Of2ww5-ObLWttarMdHCH0CDJsPWYy-3VaQJJDYX9AsIt2VSiwn320fXHJw2VhpXoN9n-MrqlGYQ1u2KLEBt~nSUFIDrq1JMoUS9u7G5zfVs8GS~2WmFAFuPW5Xag__" alt="" />
                        </div>
                        <span>Дом</span>
                    </div>
                    <div>
                        <div>
                            <img src="https://s3-alpha-sig.figma.com/img/98e8/e2fe/5ccf13c636fcca36a12b83ec344d73e6?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fRlHQDs14DLzBhFsUMVKLR7R6nMdJ1jfqFs4AFYcmFFCBJQkau8TWOhe5QkOYZSdM3R6NMrdeius-0-OZLt8JIJddw5E8d1W30qu39plDht0iUQXqguVWNbO1I2kB4WupfeCnVhzGZ-oBJChbIWEE0-~RiFLxThGShCyq6a2g6ylJEkIFXfjdLBeQ~pl2O9BOzkuSHy9PEmqPWternh915ti82YaQER3HiDY2J7sQ6uZKvU~aaPpWZgcQlDRIoMl5vX41NLBgQYgWfiYq0zBjXafqIzaA0Nh2E0sf7B0nUBtn48HQUj5F5ivMwqB0wiV0pwRkhn32ttQMjjYeuCR~Q__" alt="" />
                        </div>
                        <span>Автомобиль</span>
                    </div>
                    <div>
                        <div>
                            <img src="https://s3-alpha-sig.figma.com/img/9be7/54e1/034e348fb5aff84dcaa55b005570435f?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TwOO1qoR8lYDuE7o4T8kvla5vR6qcdEXlltU57cLmE~njaMoLL~mM58wvaQaXx~jMN2aPRycg~iN2vMzzSqOeRmivN1~RvsIskZJpBY6atvKGh7cnfxTT08k6ZYFqcyXdz~ApsW0dtOX5qEWvqScQ8CBcMLR0XhBofwIY-~TF8cIIuYBa64f7QMVAIt0GHFpGCx4GKv8tArJ0TXpiD3AlZtMyWwPoUTHSU5tnTF05AzZ2N5m81Xhz7iF4uFwIqf6UvRTteD8oRNYBQ~MUPnDGSzA6DrMQYEXS8ueBjq2V88FKI4oPeyB~poY-N78y4NRNXTTVVIP0aUhZ0lV1KHVlA__" alt="" />
                        </div>
                        <span>Путешествие</span>
                    </div>
                    <div>
                        <div>
                            <img src="https://s3-alpha-sig.figma.com/img/b033/9f07/228bafd53aaa864829d0f69048a2f3b0?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OjKcCmBnUos2TWkkdcDXODXQBcfwclOIAzK7-yXxoxJZs~T00Ycr4pTzxJE-MM-ZA9ljKQ3tD7mqseGVm8VydUeLSkocthwAw88NFVY54yVWebT0sCeqdi8M5ztuy2OaXNwRyNJ1~2V1TOazMPV~kctyH2upDl4NXDMYfqFRO9twz9U-0Ohw-AhZ6OoL3TBePRbAKWFcU44SC3xkXhfmZPLfR2tuC3bGB9tXijt-sRQZlYdtDPDNCllyXNBdZOX~e-oJdiLg5KzQ9VIhkcsvDgPw3lgaKYRDkwCvm14YtSxGC3hal3SYKpPJ7St4Qb8dsNzAYR4bZeK5-kHknIs9bg__" alt="" />
                        </div>
                        <span>Отпуск</span>
                    </div>
                    <div>
                        <div>
                            <img src="https://s3-alpha-sig.figma.com/img/149f/7150/1f6457f73ced5524f7f1955028f45b9d?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Z43TF0X7iorO5i~tQjCw2B-tWFo~7cKvJfpzUr-swSHKzAHcuG3tigf56KuknzzololabDsc97FqGP3DLg08WYECVb~fVynOLv2dSnd2niMex1w7UiYvKkQlO3DbIp9lynj~aC5Fdj4EJvJP92MdPSxFkv-ZViQ5CfJhw8Bu7s3mlhsUM~ecWqSHm5wZrO6RJFEJR5~0AkmASB-IZJ39nwabcl4uTaWtuqm6y8KxZctPTxbj1bR6GdOk3JcEX82ar-c2LoLyiVH~BbSfII8RxOKutEQ4R-pSnowVd~WrTshLBtZs9b~YtG208QCbcAMwto21fARfMyi3SU3wd5vFRQ__" alt="" />
                        </div>
                        <span>Учеба</span>
                    </div>
                    <div>
                        Добавьте <br /> свою цель <br /> +
                    </div>
                </div>
            </div>
            <h2>Партнеры</h2>
            <div className='partners'>
                <PartnersCard
                    img={'https://s3-alpha-sig.figma.com/img/ddd4/aaf4/a7ed56104f632aefb3f01c9defe1bdb3?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nE0WXAH-PI~UqL8EiRSR0jIR7r79~~K9GKcFqS5sp3IJJdmhQaTDdc1naBGQELuga~KixvaNFyyIYkzJox4mDS~mgMtvJDWQwiu5IzhDV89lK88KhSUjG4nq2BRd1vxF4ChUZ-a7wX4iDr0PRnHE8jJDd99VPs66ci7hmHWNyuaxlmthf-WvEQBWWsAMAbJydFO6ZG2h156FSIKRLGXCbwd33sq3l7tT7Z3iYXQEqTkkdObVGUqNS7AkUe3xpLvbOKDMqe0eCk-~n~m4SjTejSQT5edg23i7BZvIFTqwBkL~fRrzE5ex~RIiVLl2v6KuGt1ejjNddhjwwC91fKaFeg__'}
                />
                <PartnersCard
                    img={'https://s3-alpha-sig.figma.com/img/4c26/54ad/089a00c2d293d7e514108fb9c3a74a4c?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FyGoUqXFjjPtmGCl1s39DUu7gaoPuNIiNLhPZ9-xZZef1MoFC000UF0rtekJljNL6rCALVK1vKnpNaR8bUy6HjAGjYgpREvclwawhJ5gXIxDucYuewwyFwjYEtjU1A6WdscAhEgDO1l5lzNkoESk0RPBELGrWBT3JsC-4T17oXmQ0BUNLzdPoZIetQIC31BLkc3cfPmSehnqnwKdd9qsSzx14cN26VSOt~kzHe760RSjLcwHtcaJ9Of~ddBvc4nsUEnJZ35nC0IkgPWD16FFGWo7ZPidV-cOgu7lcvuI0JFHiwTFt9F38K6si~ohLSTC0hm85Qu9CtOJQHZltYa9DA__'}
                />
                <PartnersCard
                    img={'https://s3-alpha-sig.figma.com/img/8a6d/a729/691ee4a33dd19ba6efa62b7cfb11f5cb?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cAW1eQw06wRE0x3NrNZTKX~c1hG1UAGRhETYmEeJJNErU4JVs~7Ytx3AfL9S-b1sXtdrs1EQdSSfRmmlERPH5wTWHZvnxbxeRF2OFgbZUALLp11g~aMaizHUTgr-JUhDpTeBATiEYa~LUyRO5h1jkKk~H2iyaK2wTf525yW~SC7aDYPT~N~Sr23MkhowhDNEVNVLx9m5Civ~Fib12K~QTlvnixfwbiAXBn2RdbyfpvsnVe3MtI93YctyFK41jgjkwl00oGD4U84FBkpyWSi9jh3ErHTUDcckBP5ysHm-71yiaHiDMefv3q-rm4pSKs-rOE95dc0rEFevAao6P5G6Mw__'}
                />
                <PartnersCard
                    img={'https://s3-alpha-sig.figma.com/img/ba95/2aa2/261b016d80d8dc1ea0b3397262bed318?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YUrsVWZ1zqj1y97QCN0jebN7e1NmRGfttVaBR7ZhX5bbI0NSgzJnumukAjp5wQq1a0X-QC5RZfQ5pOW8OXL~YmlVheRldzEAWhlyntgXdJszjxJWMpl-1r5mhadUs~qHT0aWgwlJNXbtIDWhtvVB52SbHaQfLQyk8zr4SxuxMAtWHbfTYbDeUzsauR60KZO7QXkHj4nJuUhOcQ4P7suQ4RuC~TWH89X0yV3dBP78HUmBK6l2dS6RFBpSkrFVRp7vadAUQoOZ7jDix~7USN33ryxapka6JMc~09SbIMjIq6f1OYVQNjrYfQ87uZSIVzs-~S5LLXL8Mq7OVaA1BstT5w__'}
                />
                <PartnersCard
                    img={'https://s3-alpha-sig.figma.com/img/1086/c96f/7885a0b4cbeed872a0196109c15dbd6d?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SRpj2oypFWuaeBukBXk~7tjko5pz1pIdOeKjLbtd5NB811GgwuyIZtITFcs~HsOWu4VajRbLXmi-na7GCbYM7kE0kOFGTXavSPWMvDiY4GSHFT9JjWSBvcjTsyHzxAaaPc2qlkYD0UV1bi-~ydkvQTJSaZ6MORfCvla3yxceIk9GrL4SWz~WNZyv3WVZwKp2vEUa9MNt9VVhWBmkUDthi7O5TqBDGwvvjIrwOouSbyfTcBrlnevXc-j7oUfM4v~Vc5kY0OEUn1xk2rBxzEWAZPAmrzN8kP28q~LRyr1yp7BdCTeK526MKxUQrJ2NSqe8W~hCnsSpYir9KN-MfGd80g__'}
                />
                <PartnersCard
                    img={'https://s3-alpha-sig.figma.com/img/69e6/43b8/029d0840a943f3d51c87e6b2b4800361?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kj9ByxZStCKRcj21knYIhQ~~cHlRdPS5Kiaxv~17FUeqWoTarKb1rEnUK~pdCEZ1QP3rmV3TxqjYZW3aNMoQdDqwcTi0hywZCUSO0KKfCY177tZCuk-B911cBlIw9hWaDd0G5JQ1777fFJ7LmtwdGiB0b~IL04lEEq6IfJ5gaUmMiEg4HhO6Ryc21N2s14K6S8mlI-GNwxiEVdMC9pkKNHX91bsF-Gv3fd8-cZhgETjNdhM~WE9IiyCS0uQRI3figr03EJXINRlqqTczTVkrYxQ-RZBKwZxjWTlP~YSgjjaVMaXEAHcVOSK2KkiWquKGfhy6H7H7TyNzQQxPGNDKOw__'}
                />
                <PartnersCard
                    img={'https://s3-alpha-sig.figma.com/img/a3d4/2d17/9145f5b639534e39ca77af53030a1650?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Rd91A0wS33v5njkZK8UhzYuXTeB~AXVNXqOjtg~S0DU4mo59FFcHwInEQgt24NyGNtBsaxqPcnW2LaYJM84jP8SvHzFMvRhF~tG0m-oPixMkxFlfjAugAJjLC7-HHIslaId-DeVozMspPdgljkd5MHuVEesDyBdFHy-LbWFEA6O-PmZE5~NTNo1PRJoQPkeZOOU4m8PZF0oZxuhmRSqvhe88mJP-5-fg8QfUHHlsoZvboXoxsGPc0JylxnYOJA8eDtzj6y-eNobq0oGDNIAIHYQ5kXRcGHDzfH5hiePUAxmkaXKxCt4PLORHn7I6xVpotDF-MB00yWB8abqI5-4OBA__'}
                />
                <PartnersCard
                    img={'https://s3-alpha-sig.figma.com/img/a3d4/2d17/9145f5b639534e39ca77af53030a1650?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Rd91A0wS33v5njkZK8UhzYuXTeB~AXVNXqOjtg~S0DU4mo59FFcHwInEQgt24NyGNtBsaxqPcnW2LaYJM84jP8SvHzFMvRhF~tG0m-oPixMkxFlfjAugAJjLC7-HHIslaId-DeVozMspPdgljkd5MHuVEesDyBdFHy-LbWFEA6O-PmZE5~NTNo1PRJoQPkeZOOU4m8PZF0oZxuhmRSqvhe88mJP-5-fg8QfUHHlsoZvboXoxsGPc0JylxnYOJA8eDtzj6y-eNobq0oGDNIAIHYQ5kXRcGHDzfH5hiePUAxmkaXKxCt4PLORHn7I6xVpotDF-MB00yWB8abqI5-4OBA__'}
                />
            </div>
            <h2>Что такое NEO Coin?</h2>
            <div className='what'>
                <div>
                    <p>NEO Coin - это криптовалюта, разработанная нашей компанией, "NEO", Это цифровой актив, который предназначен для облегчения процессов платежей, инвестиций и обмена внутри нашей экосистемы </p>
                    <p>NEO Coin - базируется на передовых технологиях блокчейн, обеспечивая безопасность и прозрачность транзакций.</p>
                </div>
                <div>
                    <p>Наш NEO Coin предоставляет удобные решения для пользователей, позволяя им использовать криптовалюту в различных аспектах своей повседневной жизни, включая оплату товаров и услуг, участие в наших проектах и взаимодействие с нашими партнерами по всему миру.</p>
                    <Button />
                </div>
            </div>
            <div className='advantages-title'>
                <h2 style={active === 1 ? { color: '#1a1a1a' } : { color: '#9d9d9d' }}
                    onClick={() => setActive(1)}>
                    Преимущества NEO Coin
                </h2>
                <h2 style={active === 2 ? { color: '#1a1a1a' } : { color: '#9d9d9d' }}
                    onClick={() => setActive(2)}>
                    / Как использовать
                </h2>
            </div>
            {active === 1 ? (
                <Slider {...settings} className='slider-container services-slider'>
                    <Advantages
                        title={'1. Децентрализация и Безопасность'}
                        description={'NEO основан на технологии блокчейн, что обеспечивает децентрализованность. Транзакции осуществляются без промежуточных учреждений, обеспечивая высокий уровень безопасности.'}
                    />
                    <Advantages
                        title={'2. Вклад в Альтернативные Финансовые Системы'}
                        description={'Использование децентрализованных валют, таких как криптовалюты, предлагает альтернативу традиционным финансовым системам. '}
                    />
                    <Advantages
                        title={'3. Ограниченная Эмиссия и Стимулирование Роста Стоимости'}
                        description={'Ограниченное количество эмиссии NEO способствует повышению цены актива, создавая условия для стимуляции роста его стоимости.'}
                    />
                    <Advantages
                        title={'4. Глобальная Валюта для Международных Транзакций'}
                        description={'Путем пожертвований или покупки товаров и услуг, предлагаемых разработчиками и членами сообщества, можно поддержать дальнейшее развитие проекта, использующего NEO.'}
                    />
                    <Advantages
                        title={'5. NFT и цифровые активы:'}
                        description={'NEO может быть связан с покупкой и продажей цифровых активов, таких как NFT (неперемещаемые токены), которые представляют уникальные цифровые объекты или искусство.яч'}
                    />
                    <Advantages
                        title={'6. Хранение в Криптокошельке'}
                        description={'Предоставляет инвесторам выбор между горячими и холодными криптокошельками для надежного хранения.'}
                    />
                    <Advantages
                        title={' 7. Возможность Децентрализованного Инвестирования'}
                        description={'Дает возможность инвестировать в децентрализованный актив, что может привлечь тех, кто ищет альтернативные формы инвестирования.'}
                    />
                    <Advantages
                        title={'8. Осведомленность о Рисках:'}
                        description={'Предупреждает об инвестиционных рисках, стимулируя инвесторов тщательно разобраться в деталях и взвесить все аспекты перед инвестированием.'}
                    />
                </Slider>
            ) : active === 2 && (
                <Slider {...settings} className='slider-container services-slider'>
                    <Advantages
                        title={'1. Платежи и транзакции:'}
                        description={'NEO может использоваться для осуществления платежей за товары и услуги, как в онлайн-магазинах, так и в реальном мире, если местные продавцы поддерживают эту криптовалюту.'}
                    />
                    <Advantages
                        title={'2. Инвестиции и торговля:'}
                        description={'Как криптовалютный токен, NEO может использоваться для инвестирования и торговли на криптовалютных биржах.'}
                    />
                    <Advantages
                        title={'3. Участие в проектах и экосистеме:'}
                        description={'Некоторые проекты могут использовать NEO в качестве средства участия или голосования.'}
                    />
                    <Advantages
                        title={'4. Поддержка разработчиков и сообщества:'}
                        description={'Путем пожертвований или покупки товаров и услуг, предлагаемых разработчиками и членами сообщества, можно поддержать дальнейшее развитие проекта, использующего NEO.'}
                    />
                    <Advantages
                        title={'5. NFT и цифровые активы:'}
                        description={'NEO может быть связан с покупкой и продажей цифровых активов, таких как NFT (неперемещаемые токены), которые представляют уникальные цифровые объекты или искусство.яч'}
                    />
                </Slider>
            )}
            <h2>Соответствие шариату</h2>
            <div className='store-shariat'>
                <p>Соответствие принципам Исламских финансов подтверждено Советом <br />
                    Мусульман Кыргызстана, а также малазийским шейхом Д. Зиядом <br /> Мухамедом</p>
                <Button />
            </div>
            <h2>Дорожная карта</h2>
            <div className='store-map'>
                <div className='store-map-img'>
                    <img src={map} alt="" />
                </div>
                <div className='store-map-content'>
                    <div className='store-map-box1'>
                        <p>4 квартал <br /> 2024</p><hr />
                    </div>
                    <div className='store-map-box2'>
                        <p>Листинг на биржу Кыргызстана</p>
                        <p>Получение официальных документов</p>
                        <p>Стратегическое планирование и дальнейший рост</p>
                    </div>
                </div>
                <div className='store-map-content'>
                    <div className='store-map-box1'>
                        <p>1 квартал <br /> 2025</p><hr />
                    </div>
                    <div className='store-map-box2'>
                        <p>Платежная система</p>
                        <p>Валидаторы</p>
                        <p>Обменные пункты и пункты перевода</p>
                    </div>
                </div>
                <div className='store-map-content'>
                    <div className='store-map-box1'>
                        <p>2 квартал <br /> 2025</p><hr />
                    </div>
                    <div className='store-map-box2'>
                        <p>Личная платформа для перевода</p>
                        <p>Улучшение безопасности</p>
                        <p>Улучшение безопасности</p>
                    </div>
                </div>
            </div>
            <h2>Маркетплейс neo Express</h2>
            <div className='store-express'>
                <p>NEO Express - это глобальная платформа</p>
                <div className='store-express-content'>
                    <span>Почему наш маркетплейс уникален?</span>
                    <div className='store-express-text'>
                        <p>Наш масштабный маркетплейс выделяется не <br />
                            только своим глобальным охватом, но и <br />
                            удобством использования токенов NEO для <br />
                            обеспечения безопасных и эффективных <br />
                            транзакций в любой точке планеты.</p>
                        <p>Наш маркетплейс – это глобальная платформа, где <br />
                            продавцы и покупатели со всего мира могут <br />
                            встречаться, торговать и обмениваться товарами и <br />
                            услугами.</p>
                    </div>
                </div>
            </div>
            <h2>ОСОБЕННОСТИ МАРКЕТПЛЕЙСА</h2>
            <Slider {...setting} className='slider-container services-slider'>
                <FeaturesCard
                    icon={<HiOutlineLightBulb />}
                    title={'Инновационная платформа'}
                    description={'Наш масштабный маркетплейс выделяется не только своим глобальным охватом, но и удобством использования токенов NEO для обеспечения безопасных и эффективных транзакций в любой точке планеты.'}
                />
                <FeaturesCard
                    icon={<TbCoinBitcoin />}
                    title={'Поддержка криптовалюты'}
                    description={'Мы поддерживаем платежи криптовалютой, что делает наш маркетплейс удобным и доступным для пользователей, предпочитающих использовать цифровые активы для совершения покупок и продаж.'}
                />
                <FeaturesCard
                    icon={<TbWorld />}
                    title={'Глобальный охват'}
                    description={'Мы стремимся обеспечить доступ к нашему маркетплейсу для пользователей со всего мира. Наша платформа позволяет покупателям и продавцам взаимодействовать и совершать сделки вне зависимости от их местоположения.'}
                />
                <FeaturesCard
                    icon={divercity}
                    title={'Разнообразие товаров и услуг'}
                    description={'Наш маркетплейс предлагает широкий выбор товаров и услуг от различных продавцов. Покупатели могут найти все, что им нужно, начиная от товаров ручной работы и искусства до цифровых товаров и услуг.'}
                />
                <FeaturesCard
                    icon={<AiOutlineSafety />}
                    title={'Безопасные транзакции'}
                    description={'Мы придаем большое значение безопасности и прозрачности транзакций на нашем маркетплейсе. С помощью технологии блокчейн мы обеспечиваем безопасность и надежность всех финансовых операций на платформе.'}
                />
            </Slider>
            <h2>калькулятор доходности</h2>
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import './global-page.css';
// import banner from '../../../views/market/banner.jpg';
import { VscSettings } from "react-icons/vsc";
import { IoSearch } from "react-icons/io5";
import MagazinPage from '../magazin-page/magazin-page';
import AnnouncementsPage from '../announcements-page/announcements-page';
import { useDispatch } from 'react-redux';
import { getCatChoices } from '../../../App/slice/cat-choices';
import { getAllDatas } from '../../../App/slice/market2/all-datas';
import { useNavigate } from 'react-router-dom';
import { changeSearch } from '../../../App/slice/market2/filter';
import Header from '../../../containers/header/header';

export default function GlobalMarketPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        dispatch(getCatChoices());
        dispatch(getAllDatas());
    }, [dispatch]);

    useEffect(() => {
        const storedTab = localStorage.getItem('activeTab');
        if (storedTab) {
            setActiveTab(Number(storedTab));
        } else {
            setActiveTab(1);
        }
    }, []);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        localStorage.setItem('activeTab', tabIndex);
    };

    const SearchDatas = (items) => {
        dispatch(changeSearch(items));
        dispatch(getAllDatas());
    };

    return (
        <>
        <Header />
            <div className='global-market-page'>
                {/* <div className='banner-block'>
                <img src={banner} alt="Banner" />
            </div> */}
                <div className='search-block'>
                    <div className='filter-icon'>
                        <VscSettings onClick={() => navigate('/filter-market')} size={24} color='#e22717' />
                    </div>
                    <div className='search-input'>
                        <IoSearch color='#e22717' size={12} />
                        <input type="text" onChange={(e) => SearchDatas(e.target.value)} />
                    </div>
                </div>
                <div className='tab-block'>
                    <div className='tabs'>
                        <button className={activeTab === 1 ? 'tab1 active' : 'tab1'} onClick={() => handleTabClick(1)}>Магазины</button>
                        <button className={activeTab === 2 ? 'tab2 active' : 'tab2'} onClick={() => handleTabClick(2)}>Обьявления</button>
                    </div>
                    <div className='tab-content'>
                        {activeTab === 1 && <MagazinPage />}
                        {activeTab === 2 && <AnnouncementsPage />}
                    </div>
                </div>
            </div>
        </>
    );
}
import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://neocoin.io/api/',
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Token ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// let isRefreshing = false;
// let refreshSubscribers = [];

// const subscribeTokenRefresh = (cb) => {
//   refreshSubscribers.push(cb);
// };

// const onRefreshed = (token) => {
//   refreshSubscribers.forEach((cb) => cb(token));
//   refreshSubscribers = []; // Очистка подписчиков после обновления токена
// };

// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     if (error.response && error.response.status === 401 && !originalRequest._retry) {
//       if (isRefreshing) {
//         console.log('Token is refreshing, adding request to subscribers');
//         return new Promise((resolve) => {
//           subscribeTokenRefresh((token) => {
//             originalRequest.headers['Authorization'] = 'Bearer ' + token;
//             resolve(api(originalRequest));
//           });
//         });
//       }

//       originalRequest._retry = true;
//       isRefreshing = true;

//       const refreshToken = localStorage.getItem('refreshToken');
//       if (!refreshToken) {
//         console.log('No refresh token, redirecting to login');
//         localStorage.removeItem('accessToken');
//         localStorage.removeItem('refreshToken');
//         window.location.href = '/login';
//         return Promise.reject(error);
//       }

//       console.log('Attempting to refresh token');
//       try {
//         const { data } = await axios.post('https://asmanmarket.com/api/auth/token/refresh/', { refresh: refreshToken });
//         console.log('Token refreshed successfully:', data);

//         localStorage.setItem('accessToken', data.access);
//         api.defaults.headers.common['Authorization'] = 'Bearer ' + data.access;
//         originalRequest.headers['Authorization'] = 'Bearer ' + data.access;
//         onRefreshed(data.access);
//         return api(originalRequest);
//       } catch (err) {
//         console.log('Failed to refresh token', err);
//         localStorage.removeItem('accessToken');
//         localStorage.removeItem('refreshToken');
//         window.location.href = '/login';
//         return Promise.reject(err);
//       } finally {
//         isRefreshing = false;
//       }
//     }

//     return Promise.reject(error);
//   }
// );


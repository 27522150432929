// import { useZxing } from "react-zxing";
// import { useNavigate } from "react-router-dom";
// import './qr-scanner.css'

// const QRScanner = () => {
//     const navigate = useNavigate();

//     const { ref, stop } = useZxing({
//         constraints: {
//             video: {
//                 facingMode: "environment"
//             }
//         },
//         onDecodeResult(result) {
//             let text = result.getText();
//             if (text.endsWith("?type=1")) {
//                 text = text.replace("?type=1", "");
//                 navigate(`/details-qr/${text}`);
//             } else if (text.endsWith("?type=2")) {
//                 text = text.replace("?type=2", "");
//                 navigate(`/discount-detail/${text}`);
//             } else {
//                 navigate(`/with-drawal/${text}`)
//             }
//             stop();
//         },
//     });

//     return (
//         <div className="scanner-container">
//             <div className="video-container">
//                 <video ref={ref} className="scan-video" controls={false} />
//             </div>
//         </div>
//     );
// };


// export default QRScanner;

import { Scanner } from '@yudiel/react-qr-scanner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './qr-scanner.css'
import { FiChevronLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';

const QRScanner = () => {

    const [scanning, setScanning] = useState(true);
    const [active, setActive] = useState(2);
    const navigate = useNavigate()
    const user_qr = useSelector((state) => state.user_info.user_info);
    console.log(user_qr.qr, 'qr');
    

    const result_success = (text) => {
        setScanning(false);
        let succes_text = text
        if (text.endsWith("?type=1")) {
            succes_text = text.replace("?type=1", "");
            navigate(`/details-qr/${succes_text}`);
        } else if (text.endsWith("?type=2")) {
            succes_text = text.replace("?type=2", "");
            navigate(`/discount-detail-forsale/${succes_text}`);
        } else {
            navigate(`/with-drawal/${text}`)
        }
    }

    const handleShare = async () => {
        try {
            await navigator.share({
                title: 'Поделиться моим QR-кодом',
                text: `Мой ID для переводов: \n ${user_qr.id}.\nСканируйте мой QR-code в приложении NEO Fund.`,
                url: user_qr.qr,
            });
        } catch (error) {
            console.error('Ошибка обмена:', error.message);
        }
    };

    const handleFallbackShare = () => {
        alert('Вызван альтернативный метод обмена QR-кодом');
    };

    return (
        <div className='scanner-container'>
            <div>
                <FiChevronLeft onClick={() => navigate('/')} color='#fff' size={36} />
                <span>QR сканер</span>
            </div>
            <div className='contain-scan_cam'>
                <div className='scan_cam_title'>
                    <p className={active === 1 ? 'scan_cam_active' : ''}
                        onClick={() => setActive(1)}>Мой QR-code</p>
                    <p className={active === 2 ? 'scan_cam_active' : ''}
                        onClick={() => setActive(2)}>QR сканер</p>
                </div>
                {active === 2 ? scanning && (
                    <div className='scan_cam'>
                        <h1>Наведите камеру на QR</h1>
                        <Scanner
                            facingMode="environment"
                            facingModeExact
                            askPermission
                            onResult={result_success}
                            onError={error => console.log(error?.message)}
                        />
                    </div>
                ) : active === 1 && (
                    <div className="container-qr-block">
                        <p>Получайте переводы с удобством и безопасностью прямо в приложении</p>
                        <div className="qr-block">
                            <img src={user_qr.qr} alt="" />
                            <h1>{user_qr.id}</h1>
                        </div>
                        {navigator.share ? (
                            <button className="share-button-qr" onClick={handleShare}>
                                Поделиться
                            </button>
                        ) : (
                            <button className="share-button-qr" onClick={handleFallbackShare}>
                                Поделиться
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default QRScanner
import React, { useEffect, useState } from 'react'
import './announcements-page.css'
import { IoIosCreate } from "react-icons/io";
import { AiFillLike } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { FaSignsPost } from "react-icons/fa6";
import { api } from '../../../Api';
import ProductCard from '../components/card/card';
import LoadingAnimate from '../../../UI-kit/loading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyAds } from '../../../App/slice/market2/myAds';
export default function AnnouncementsPage() {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const myPosts = useSelector(state => state.myAds.myAds);

    useEffect(() => {
        dispatch(fetchMyAds());
    }, []);

    return (
        <div className='announcements-page'>
            <div className='announcements-block'>
                <div onClick={() => navigate('/ads-post')}><IoIosCreate size={20} color='#e22717' />Создать объявление</div>
                <div onClick={() => navigate('/favorite-market')}><AiFillLike size={20} color='#e22717' />Избранные</div>
            </div>
            <h1 className='announcements-title'><FaSignsPost size={20} color='#e22717' />Мои обьявления</h1>
            <div className='announcements-list'>
                {myPosts ? myPosts.map((el, index) => (
                    <ProductCard navigates={true} data={el} index={index} />
                )) : <LoadingAnimate />}
            </div>
            <div style={{ width: "100%", height: 100 }}></div>
        </div>
    )
}
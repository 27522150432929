import React, { useEffect, useState } from "react";
import "./stories.css";
import StorisContent from "./stories-component";
import { api } from "../../Api";
import { Skeleton } from "@mui/material";

const Storis = () => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get("/stories")
      .then((response) => {
        setStories(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="storis">
      <div className="storis_blockss">
        <div className="flip">
          {loading ? (
            <div style={{ display: "flex", gap: "10px" }}>
              <Skeleton sx={{ bgcolor: 'grey.900' }} width='90px' height='90px' variant="rounded" />
              <Skeleton sx={{ bgcolor: 'grey.900' }} width='90px' height='90px' variant="rounded" />
              <Skeleton sx={{ bgcolor: 'grey.900' }} width='90px' height='90px' variant="rounded" />
              <Skeleton sx={{ bgcolor: 'grey.900' }} width='90px' height='90px' variant="rounded" />
              <Skeleton sx={{ bgcolor: 'grey.900' }} width='90px' height='90px' variant="rounded" />
            </div>
          ) : (
            stories.map((el, id) => <StorisContent key={id} data={el} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default Storis;

import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../Api'
import { Navigate } from 'react-router-dom'

const user_info = createSlice({
    name: 'user-info',
    initialState: {
        user_info: []
    },
    reducers: {
        userInfo: (state, action) => {
            state.user_info = action.payload
        }
    }
})

export const { userInfo } = user_info.actions
export default user_info.reducer


export const fetchUserData = () => {
    return async (dispatch) => {
        try {
            const response = await api.get("/auth/user-info");
            if (response.status === 200) {
                dispatch(userInfo(response.data));
            } else if (response.status >= 401 && response.status < 500) {
                // window.location.href = "/login";
                return <Navigate to='/login' />
            }
        } catch (error) {
            console.log(error);
        }
    };
};
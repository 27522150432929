import React, { useEffect, useState, useCallback } from 'react'
import './home-page.css'
import Header from '../../containers/header/header'
import Balance from './components/balance/balance'
import { IoCopyOutline, IoQrCodeOutline } from "react-icons/io5";
import { BiScan } from "react-icons/bi";
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchStatusData } from '../../App/slice/status';
import { fetchUserData } from '../../App/slice/user-info';
import NEO4 from '../../views/disc/Standart_home.png'
import NEO3 from '../../views/disc/Bronze_home.png'
import NEO2 from '../../views/disc/Silver_home.png'
import NEO1 from '../../views/disc/Gold_home.png'
import NEO from '../../views/disc/Vip_home.png'
import expenses from '../../views/disc/expenses.png'
import Storis from '../../containers/stories/stories';
// import Skeleton from 'react-loading-skeleton';
import Modal from '../../containers/UI/Modal/Modal';
import coin from '../../views/coins/logo.png'
import { TbClipboardCopy } from "react-icons/tb";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { fetchNotifData } from '../../App/slice/notification';
import { api } from '../../Api';
import { PiArrowsLeftRightLight } from "react-icons/pi";
import { GiLaurelsTrophy } from "react-icons/gi";
import ServicesSlider from './components/services/services-slider';

export default function HomePage() {

    const userData = useSelector(state => state.user_info.user_info)
    const getStatus = useSelector(state => state.status.status)
    const dispatch = useDispatch()
    localStorage.setItem('userID', userData.id)

    const fetchData = useCallback(() => {
        dispatch(fetchNotifData());
        dispatch(fetchUserData());
        dispatch(fetchStatusData());
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const statusImage = [
        { img: NEO4 },
        { img: NEO3 },
        { img: NEO2 },
        { img: NEO1 },
        { img: NEO }
    ]


    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false)
    const [copied, setCopied] = useState(false);
    const [count_user, setCount_user] = useState({})
    const count_users = count_user.count ? count_user.count.toString().split('') : []
    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };


    const handleShareLink = async () => {
        try {
            await navigator.share({
                title: 'Приглашение на платформу NEO',
                text: `Вас пригласил ${userData.first_name} на платформу NEO. Перейдите по ссылке и зарегистрируйтесь:`,
                url: `https://neocoin.io/#/register/${userData.id}`
            });
        } catch (error) {
            console.error('Ошибка обмена:', error.message);
        }
    };

    useEffect(() => {
        const getCountUsers = async () => {
            try {
                const response = await api.get('/count-users')
                setCount_user(response.data)
            } catch (error) {
                console.log(error);
            }
        }

        getCountUsers()
    }, [])

    useEffect(() => {
        if (userData && Object.keys(userData).length > 0 && getStatus && Object.keys(getStatus).length > 0) {
            setLoading(false);
        }
    }, [userData, getStatus]);

    const navigate = useNavigate()
    return (
        <div className='home-page'>
            <Header />
            <Storis />
            <div className='section1'>
                <div className='status_block'>
                    <div onClick={() => navigate('/expenses')} className='expenses boxx'>
                        <h3>За октябрь</h3>
                        <img src={expenses} alt="" />
                        <p>17 459 c</p>
                    </div>
                    <div onClick={() => navigate('/bonuses-and-statuses')} className='bonus_status boxx'>
                        <h3>Бонусы и статусы</h3>
                        <div>
                            {statusImage.map((item, index) => (
                                <img key={index} src={item.img} alt="" />
                            ))}
                        </div>
                    </div>
                </div>
                <ServicesSlider />
                <Balance info={getStatus.main_info} balance={userData.balance} />
                <div className='curs-usdt'>
                    <div>
                        <p>1</p>
                        <span style={{ fontFamily: 'Glasten', fontWeight: '400', fontSize: '14px' }}>NEO</span>
                    </div>
                    <PiArrowsLeftRightLight size={20} />
                    <div>
                        <p>{getStatus.rate}</p>
                        <span>USDT</span>
                    </div>
                </div>
                    <div className='referal-contain'>
                        <div onClick={() => setModal(!modal)}>
                            <h1>Реферальная программа</h1>
                        </div>
                        {modal &&
                            <Modal setIsModalOpen={setModal} color="white">
                                <h1 className='text-referal-home_page_main'>Приглашай друзей <br /> в NEO Fund</h1>
                                <p className='text-referal-home_page'>За каждую покупку Neo Coin, совершенную приглашенным другом, вам будет начисляться <span style={{ color: '#e22717' }}>5%</span></p>
                                <div className='asman-coin-contain'>
                                    <img src={coin} alt="" className='asman-coin-referal' />
                                    <div>
                                        <h1>Ваша персональная ссылка</h1>
                                        <div>
                                            <p>{`https://neocoin.io/#/register/${userData.id}`}</p>
                                            <CopyToClipboard text={`https://neocoin.io/#/register/${userData.id}`} onCopy={handleCopy}>
                                                <IoCopyOutline className='button-referal-home_page' size={24} />
                                            </CopyToClipboard>
                                        </div>
                                        {copied && <p className='copyed-referal'>Скопирован</p>}
                                    </div>
                                    <button onClick={handleShareLink}>Пригласить</button>
                                    <button onClick={() => navigate('/referal')} style={{ border: '2px solid #e22717', color: '#e22717', background: 'transparent' }}>Узнать больше</button>
                                </div>
                            </Modal>
                        }
                    </div>
            </div>
        </div >
    )
}
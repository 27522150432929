import { IoMdChatbubbles } from "react-icons/io";
import { HiHome } from "react-icons/hi";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { BsBarChartLine } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { CgQr } from "react-icons/cg";

const routes = [
    {
        path: '/',
        // name: 'Главная',
        icon: <HiHome size={25} />
    },
    {
        path: '/market',
        // name: 'Маркет',
        icon: <HiOutlineShoppingBag size={25} />
    },
    {
        path: '/qr-scanner',
        // name: 'Профиль',
        icon: <CgQr size={25} />
    },
    {
        path: '/messages',
        // name: 'Чат',
        icon: <IoMdChatbubbles size={25} />
    },
    {
        path: '/birzha',
        // name: 'Биржа',
        icon: <BsBarChartLine size={25} />
    }
]

export default routes
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { api } from "../../../Api";
import logo from "../../../views/coins/logo.png";
import LoadingAnimate from "../../../UI-kit/loading";

const ReserveCode = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.post("/auth/resend-email/", {
                email,
            });
            if (response.data.response === true) {
                localStorage.setItem("email", email);
                navigate("/send-code");
            } else {
                if (response.data.message) {
                    alert(response.data.message, "error");
                }
                setError(response.data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <div className="container2">
            <div className="images">
                <div className="coin">
                    <img src={logo} alt="" />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="submit">
                        <h1>Не получили код подтверждения?</h1>
                        <p className="text_gray">
                            Мы отправим код на вашу электронную почту
                        </p>
                        <div className="inputs">
                            <input
                                className="input_form"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                placeholder="Email"
                                required
                                autoComplete="off"
                            />
                            {error.email && <p className="red">{error.email}</p>}
                            <button
                                disabled={loading}
                                style={{ marginBottom: 28, background: loading ? '#f93d2c' : "#E22717" }}
                                onSubmit={handleSubmit}
                                className="forgot-password"
                            >
                                {loading ? <LoadingAnimate /> : "Получить код"}
                            </button>
                        </div>
                    </div>
                </form>
                <p className="footer-text3">
                    Есть аккаунт ?
                    <NavLink to={"/login"} className="register3">
                        Войдите
                    </NavLink>
                </p>
            </div>
        </div>
    );
};

export default ReserveCode;

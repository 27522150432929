import React, { useState } from 'react'
import './headerStore.scss'
import { NavLink } from 'react-router-dom'
import { IoIosArrowDown, IoIosMenu, IoMdClose, IoIosArrowUp, IoMdCheckmark } from "react-icons/io";
import russian from '../../../views/neocoin.store/russian.png'
import english from '../../../views/neocoin.store/english.png'

export default function Header() {

    const [openMenu, setOpenMenu] = useState(false)
    const [isClosing, setIsClosing] = useState(false);
    const [dropDown, setDropDown] = useState(null)

    const toggleMenu = () => {
        if (openMenu) {
            setIsClosing(true);
            setTimeout(() => {
                setOpenMenu(!openMenu)
                setIsClosing(false);
            }, 900)
        } else {
            setOpenMenu(!openMenu)
        }
    }

    const toggleDropDown = (i) => {
        setDropDown(dropDown === i ? null : i)
    }

    return (
        <>
            {openMenu && <div className='bg' onClick={toggleMenu}></div>}
            <div className='header_store'>
                <div className='logo'><NavLink to='/store' style={{ color: '#e22717' }}>NEO</NavLink></div>
                <ul className='store_menu'>
                    <li onClick={() => toggleDropDown(1)}>Подробнее {dropDown === 1 ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />}</li>
                    {dropDown === 1 && (
                        <ul className='drop_down'>
                            <li onClick={toggleDropDown}><NavLink>Как работает NEO Coin</NavLink></li>
                            <li onClick={toggleDropDown}><NavLink>Часто задаваемые вопросы</NavLink></li>
                        </ul>
                    )}
                    <li><NavLink to='#'>Контакты</NavLink></li>
                    <li onClick={() => toggleDropDown(2)}>О компании {dropDown === 2 ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />}</li>
                    {dropDown === 2 && (
                        <ul className='drop_down about'>
                            <li onClick={toggleDropDown}><NavLink>Документы</NavLink></li>
                            <li onClick={toggleDropDown}><NavLink>Руководство</NavLink></li>
                        </ul>
                    )}
                    <li><NavLink to='#'>Соответствие шариату</NavLink></li>
                    <li onClick={() => toggleDropDown(3)}> <img src={russian} alt="" /> Русский {dropDown === 3 ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />}</li>
                    {dropDown === 3 && (
                        <ul className='drop_down language'>
                            <li onClick={toggleDropDown}> <img src={english} alt="" />English</li>
                        </ul>
                    )}
                </ul>
                {openMenu && (
                    <div className={`menu ${isClosing ? 'closing' : ''}`}>
                        <div className="menu_close">
                            <IoMdClose onClick={toggleMenu} size={24} style={{ cursor: 'pointer' }} />
                        </div>
                        <ul className='store_menu'>
                            <li><NavLink to='#'>Как работает NEO Coin</NavLink></li>
                            <li><NavLink to='#'>Часто задаваемые вопросы</NavLink></li>
                            <li><NavLink to='#'>Контакты</NavLink></li>
                            <li><NavLink to='#'>Документы</NavLink></li>
                            <li><NavLink to='#'>Руководство</NavLink></li>
                            <li><NavLink to='#'>Соответствие шариату</NavLink></li>
                            <li> <img src={russian} alt="" />Русский <IoMdCheckmark size={24} /></li>
                            <li> <img src={english} alt="" />English</li>
                        </ul>
                    </div>
                )}
                <IoIosMenu className='icon_menu' onClick={toggleMenu} size={24} />
            </div>
        </>
    )
}

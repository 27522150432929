import { useState, useEffect, useRef } from "react";
import { api } from "../../../Api";
import { useNavigate, useParams } from "react-router-dom";
import './ads-update.css';
import LoadingAnimate from "../../../UI-kit/loading";
import { FiChevronLeft } from "react-icons/fi";
import { toast } from 'react-toastify';
import { MdDeleteForever } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

export default function AdsUpdate() {
    const [formData, setFormData] = useState({
        is_active: true,
        cat: '',
        title: '',
        city: '',
        currency: 'СОМ',
        description: '',
        price: 0,
        price_asman: 0,
        number: '',
        images: []
    });
    const [selectedCurrency, setSelectedCurrency] = useState('СОМ');
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isActive, setActive] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const imageInputRef = useRef(null);
    const inputRef = useRef(null);

    const getUpdateData = async () => {
        try {
            const response = await api.get(`/market/ad-update/${id}`);
            setFormData(response.data);
            setSelectedCurrency(response.data.currency);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getUpdateData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setFormSubmitted(false);
    };

    const handleCurrencyChange = (e) => {
        const { value } = e.target;
        setSelectedCurrency(value);
        setFormData(prevState => ({
            ...prevState,
            currency: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!formData.title || !formData.description || !formData.price || !formData.price_asman || !formData.number) {
            setFormSubmitted(true);
            setLoading(false);
            return;
        }
        try {
            await api.post(`/market/ad-update/${id}`, formData);
            toast.success('Данные успешно сохранены!', {
                position: "top-right",
                autoClose: 3000
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const deactive = async () => {
        setActive(true);
        try {
            const updatedIsActive = !formData.is_active;
            await api.post(`/market/ad-update/${id}`, { is_active: updatedIsActive });
            toast.success(`${updatedIsActive ? 'Активация объявления завершена!' : 'Деактивация объявления завершена!'}`, {
                position: "top-right",
                autoClose: 3000
            });
            setFormData(prevState => ({
                ...prevState,
                is_active: updatedIsActive
            }));
        } catch (error) {
            console.log(error);
        } finally {
            setActive(false);
        }
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageData = new FormData();
            imageData.append('img', file);

            try {
                const response = await api.post(`/market/ad-img/${id}/`, imageData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setFormData(prevState => ({
                    ...prevState,
                    images: [...prevState.images, response.data]
                }));
                toast.success('Изображение успешно загружено!', {
                    position: "top-right",
                    autoClose: 3000
                });
                imageInputRef.current.value = '';
                getUpdateData()
            } catch (error) {
                console.log(error);
                toast.error('Ошибка загрузки изображения!', {
                    position: "top-right",
                    autoClose: 3000
                });
            }
        }
    };

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        imageInputRef.current.click();
    };

    const handleImageUpdate = async (imageId, file) => {
        const imageData = new FormData();
        imageData.append('img', file);
        try {
            await api.post(`/market/ad-img/update/${imageId}/`, imageData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const updatedImages = formData.images.map(image =>
                image.id === imageId ? { ...image, img: URL.createObjectURL(file) } : image
            );
            setFormData(prevState => ({
                ...prevState,
                images: updatedImages
            }));
            toast.success('Изображение успешно обновлено!', {
                position: "top-right",
                autoClose: 3000
            });
            inputRef.current.value = '';
            getUpdateData()
        } catch (error) {
            console.log(error);
            toast.error('Ошибка обновления изображения!', {
                position: "top-right",
                autoClose: 3000
            });
        }
    };

    const handleImageDelete = async (imageId) => {
        try {
            await api.post(`/market/ad-img/delete/${imageId}/`);
            setFormData(prevState => ({
                ...prevState,
                images: prevState.images.filter(image => image.id !== imageId)
            }));
            toast.success('Изображение успешно удалено!', {
                position: "top-right",
                autoClose: 3000
            });
        } catch (error) {
            console.log(error);
            toast.error('Ошибка удаления изображения!', {
                position: "top-right",
                autoClose: 3000
            });
        }
    };

    return (
        <div className="ads-update">
            <FiChevronLeft style={{ padding: 0, marginLeft: '-10px', marginBottom: '10px' }} color='#fdb602' onClick={() => navigate('/market')} size={30} />
            <fieldset>
                <input
                    ref={imageInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                />
                <div className="image-preview">
                    {formData.images.map((image, index) => (
                        <div className="image-container" key={index}>
                            <img
                                className="image-item"
                                src={`https://neocoin.io${image.img}`}
                                alt={`Image ${index}`}
                            />
                            <div className="image-actions">
                                <AiFillEdit
                                    className="edit-icon"
                                    color="var(--orange)"
                                    size={25}
                                    onClick={() => inputRef.current.click()}
                                />
                                <MdDeleteForever className="delete-icon" color="var(--orange)" size={25} onClick={() => handleImageDelete(image.id)} />
                            </div>
                            <input
                                ref={inputRef}
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={(e) => handleImageUpdate(image.id, e.target.files[0])}
                            />
                        </div>
                    ))}
                </div>
                <button type="button" onClick={() => imageInputRef.current.click()}>Добавить изображение</button>
            </fieldset>
            <form onSubmit={handleSubmit}>
                <label>
                    Название:
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Описание:
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Цена:
                    <input
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                    />
                </label>
                <legend>Валюта:</legend>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        СОМ
                        <input
                            type="radio"
                            value="СОМ"
                            checked={selectedCurrency === 'СОМ'}
                            onChange={handleCurrencyChange}
                        />
                    </label>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        USD
                        <input
                            type="radio"
                            value="USD"
                            checked={selectedCurrency === 'USD'}
                            onChange={handleCurrencyChange}
                        />
                    </label>
                </div>
                <label>
                    Цена за NEO Coin:
                    <input
                        type="number"
                        name="price_asman"
                        value={formData.price_asman}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Телефон номер:
                    <input
                        type="text"
                        name="number"
                        value={formData.number}
                        onChange={handleChange}
                    />
                </label>
                {formSubmitted && (
                    <span>Заполните все обязательные поля</span>
                )}
                <button type="submit"
                    style={{ background: loading ? '#bba97a' : "#fdb602" }}
                    disabled={loading}>
                    {loading ? <LoadingAnimate /> : "Изменить"}
                </button>
            </form>
            <button type="button"
                style={{ background: formData.is_active ? 'red' : 'green', marginTop: '10px' }}
                onClick={deactive}
                disabled={isActive}>
                {isActive ? <LoadingAnimate /> : formData.is_active ? "Деактивировать" : "Активировать"}
            </button>
            <div style={{ width: '100%', height: '100px' }}></div>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import './referal-page.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../../App/slice/user-info';
import { api } from '../../../Api';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaStar } from "react-icons/fa";
import UserList from './components/user-list';
import { AiFillQuestionCircle } from "react-icons/ai";
import LoadingAnimate from '../../../UI-kit/loading';
import Modal from '../../../containers/UI/Modal/Modal';

export default function ReferalPage() {
    const [referalData, setReferalData] = useState([]);
    const [referalData2, setReferalData2] = useState([]);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const asman_detail = useSelector(state => state.status.status);

    useEffect(() => {
        dispatch(fetchUserData());
        fetchReferalData();
    }, [dispatch]);

    const fetchReferalData = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await api.get('auth/ref-list/');
            const response2 = await api.get('auth/ref-program/');
            setReferalData(response.data);
            setReferalData2(response2.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const groupedUsers = referalData.reduce((acc, user) => {
        const status = user.status;
        if (!acc[status]) {
            acc[status] = [];
        }
        acc[status].push(user);
        return acc;
    }, {});

    const totalAmount = referalData.reduce((sum, user) => {
        return sum + user.bonuses.reduce((acc, bonus) => acc + bonus.amount, 0);
    }, 0);

    return (
        <div className='referal_container'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <FiChevronLeft color='#fff' onClick={() => navigate('/profile')} size={36} />
                    <span>Рефералы</span>
                <AiFillQuestionCircle onClick={() => setModal(!modal)} color='#fff' />
            </div>
            <div>
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <h1>Заработано всего:</h1>
                        <p style={{ marginTop: '10px', color: '#e22717', fontSize: '24px', fontWeight: '600' }}>
                            {totalAmount || 0} <span style={{ fontFamily: 'Glasten', fontSize: '20px', fontWeight: '400' }}>NEO</span>
                            <span style={{ fontSize: '14px' }}>Coin</span>
                        </p>
                    </div>
                </div>
                <div className='container_radial'>
                    {referalData2.count && Object.entries(referalData2.count).map(([item, data]) => (
                        <div key={item}>
                            <p>{item === 'neo_4' ? 'NEO-4'
                                : item === 'neo_3' ? 'NEO-3'
                                    : item === 'neo_2' ? 'NEO-2'
                                        : item === 'neo_1' ? 'NEO-1' : 'NEO'}</p>
                            <CircularProgressbar
                                className="custom-progress-bar"
                                value={data}
                                text={`${data}/${referalData2.program[item]}`}
                                strokeWidth={20}
                                styles={buildStyles({
                                    width: '200px',
                                    pathColor: '#52b202',
                                    // textColor: '#fff',
                                    trailColor: '#282828de',
                                    textSize: '20px',
                                })}
                            />
                        </div>
                    ))}
                </div>
                <div className='referal_friends'>
                    <h1>Приглашённые друзья:</h1>
                    {loading ? (
                        <div style={{
                            width: '100%',
                            height: '30vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}><LoadingAnimate /></div>
                    ) : (
                        referalData.length ? <UserList users={groupedUsers} />
                            : <div style={{
                                width: '100%',
                                height: '30vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>Пока нет приглашённых</div>
                    )}
                </div>
            </div>
            {modal && (
                <Modal setIsModalOpen={setModal} color="white">
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                        color: "black"
                    }}>
                        <p dangerouslySetInnerHTML={{ __html: asman_detail.referral_info }} />
                    </div>
                </Modal>
            )}
        </div>
    );
}

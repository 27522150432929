import React, { useState } from 'react'
import './change-data.css'
import '../change-password/change-password.css'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

export default function ChangeData() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    return (
        <div className="change_password">
            <div className="head">
                <FiChevronLeft color='#fff' onClick={() => navigate(-1)} size={36} />
                <span>Изменить пароль</span>
            </div>
            <div className="change_block change_block_data">
                <form className="form_password">
                    <div className="input_box">
                        <label className="label_form">Имя</label>
                        <input
                            className="input_form"
                            // value=''
                            // onChange=''
                            type="text"
                            placeholder="Имя"
                            // required
                        />
                    </div>
                    <div className="input_box">
                        <label className="label_form">Фамилия</label>
                        <input
                            className="input_form"
                            // value=''
                            // onChange=''
                            type="text"
                            placeholder="Фамилия"
                            // required
                        />
                    </div>
                    <div className="input_box">
                        <label className="label_form">Отчество</label>
                        <input
                            className="input_form"
                            value=''
                            onChange=''
                            type="text"
                            placeholder="Отчество"
                            required
                        />
                    </div>
                    <div className="input_box">
                        <label className="label_form">Дата рождения</label>
                        <input
                            className="input_form"
                            value=''
                            onChange=''
                            type="date"
                            placeholder="Дата рождения"
                            required
                        />
                    </div>
                    <div className="input_box">
                        <label className="label_form">Номер телефона</label>
                        <input
                            className="input_form"
                            value=''
                            onChange=''
                            type="number"
                            placeholder="Номер телефона"
                            required
                        />
                    </div>
                    <div className="input_box">
                        <label className="label_form">Электронная почта</label>
                        <input
                            className="input_form"
                            value=''
                            onChange=''
                            type="email"
                            placeholder="E-mail"
                            required
                        />
                    </div>
                    <div className="input_box">
                        <label className="label_form">Страна</label>
                        <input
                            className="input_form"
                            value=''
                            onChange=''
                            type="text"
                            placeholder="Страна"
                            required
                        />
                    </div>
                    <div className="input_box">
                        <label className="label_form">Населенный пункт</label>
                        <input
                            className="input_form"
                            value=''
                            onChange=''
                            type="text"
                            placeholder="Населенный пункт"  
                            required
                        />
                    </div>
                    <button
                        className="button_form"
                    >
                        {loading ? "loading..." : "Изменить"}
                    </button>
                </form>
            </div>
        </div>
    )
}

import { Skeleton } from "@mui/material";
import { LiaTelegram } from "react-icons/lia";
import { IoIosNotificationsOutline, IoIosMenu, IoIosClose } from "react-icons/io"; import React, { useState } from "react";
import "./header.css";
import { useSelector } from "react-redux";
// import Skeleton from "react-loading-skeleton";
import { NavLink, useNavigate } from "react-router-dom";
import { api } from "../../Api";


export default function Header() {

  const [openMenu, setOpenMenu] = useState(false)
  const [isClosing, setIsClosing] = useState(false);

  const toggleMenu = () => {
    if (openMenu) {
      setIsClosing(true);
      setTimeout(() => {
        setOpenMenu(!openMenu)
        setIsClosing(false);
      }, 900)
    } else {
      setOpenMenu(!openMenu)
    }
  }

  const names = useSelector((state) =>
    state.user_info.user_info);
  const news = useSelector((state) => state.notification.news);
  const newsLength = news.filter(el => el.is_read === false)
  const sumnotifications = names.notifications + newsLength.length
  const navigate = useNavigate()

  return (
    <div className="contain-header">
      {openMenu && <div className="menu_bg" onClick={toggleMenu}></div>}
      <div className="header">
        <div className="profile-name" onClick={() => navigate('/profile')}>
          {names.profile_photo ? (
            <img
              className="profile-photo-header"
              src={names.profile_photo}
              alt=""
            />
          ) : (
            <Skeleton sx={{ bgcolor: 'grey.900' }} width={50} height={50} variant="circular" />
          )}
          <h1>{names.first_name || <Skeleton sx={{ bgcolor: 'grey.900' }} width={70} height={10} variant="text" />}</h1>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <NavLink target="_blank" to='https://t.me/neocoin_store'><LiaTelegram size={25} /></NavLink>
          <div onClick={() => navigate('/natification-page')} style={{ margin: '5px', position: "relative", cursor: "pointer" }}>
            {sumnotifications > 0 ? <div className="count_notification">{sumnotifications}</div> : ""}
            <IoIosNotificationsOutline size={25} />
          </div>
          <IoIosMenu size={25} onClick={toggleMenu} style={{ cursor: "pointer" }} />
        </div>
        {openMenu && (
          <div className={`menu ${isClosing ? 'closing' : ''}`}>
            <div className="menu_close">
              <IoIosClose size={30} onClick={toggleMenu} style={{ cursor: "pointer" }} />
            </div>
            <ul>
              <li><NavLink to='#' onClick={toggleMenu}>Платежи</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>Видео</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>Новости</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>Такси</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>Авиабилеты</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>Фонд</NavLink></li>
              <li><NavLink to='/discount' onClick={toggleMenu}>Скидки</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>Наши объекты</NavLink></li>
              <li><NavLink to='/store' onClick={toggleMenu}>neocoin криптовалюта</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>О нас</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>Знакомство</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>Голосования</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Express"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Logy"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Med"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Energy"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Life"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Bank"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Center"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Industry"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Coin"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Milk"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Textile"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Vitamins"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Lab"</NavLink></li>
              <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Food"</NavLink></li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

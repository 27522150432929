import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './App/store.js';
// import { Buffer } from 'buffer';
// import process from 'process';
// import crypto from 'crypto-browserify';
// import stream from 'stream-browserify';
// import assert from 'assert';


// window.Buffer = Buffer;
// window.process = process;
// window.crypto = crypto;
// window.stream = stream;
// window.assert = assert;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </HashRouter>
);

reportWebVitals();

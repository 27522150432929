import React, { useState } from "react";
import "./support.css";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Modal from "../../../containers/UI/Modal/Modal";
import { BiMessageSquareEdit, BiLogoInstagram } from "react-icons/bi";
import { RiTelegramLine } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { FiChevronLeft } from "react-icons/fi";

const Support = () => {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="support">
      <div className="head">
        <FiChevronLeft onClick={() => navigate(-1)} size={36} />
        <span>Служба поддержки</span>
      </div>
      <div className="support_block">
        <div className="ave">
          <div className=""></div>
          <div onClick={() => setModal(true)} className="box down">
            <div className="flex">
              <BiMessageSquareEdit size={32} />
              <p className="text">Написать</p>
            </div>
            <IoIosArrowForward className="icon" size={24} />
          </div>
        </div>
        <div className="save">
          <a
            target="_blank"
            href="https://instagram.com/neocoin_store"
          >
            <div className="box top1">
              <div className="flex">
                <BiLogoInstagram size={32} />
                <p className="text">Instagram</p>
              </div>
              <IoIosArrowForward className="icon" size={24} />
            </div>
          </a>
          <a target="_blank" href="https://t.me/neo_coin_chat">
            <div className="box down">
              <div className="flex">
                <RiTelegramLine size={32} />
                <p className="text">Telegram</p>
              </div>
              <IoIosArrowForward className="icon" size={24} />
            </div>
          </a>
        </div>
        <div className="save">
          <div className="box top1">
            <div>
              <p className="label">Верся приложения</p>
              <p className="text" style={{ fontWeight: "400" }}>+1.1.10</p>
            </div>
          </div>
          <div className="box center">
            <div>
              <p className="label">Модель</p>
              <p className="text" style={{ fontWeight: "400" }}>iOS iphone 15 pro</p>
            </div>
          </div>
          <div className="box down">
            <div>
              <p className="label">Версия</p>
              <p className="text" style={{ fontWeight: "400" }}>iOS 17.3.1</p>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <Modal setIsModalOpen={setModal}>
          <div className="block">
            <h2>Написать </h2>
            <a target="_blank" href="#">
              <div className="box modal_top">
                <div className="flex">
                  <FaWhatsapp size={32} />
                  <p className="text">Whatsapp</p>
                </div>
                <IoIosArrowForward className="icon" size={24} />
              </div>
            </a>
            <a target="_blank" href="https://t.me/neo_ceo_bot">
              <div className="box modal_bottom">
                <div className="flex">
                  <RiTelegramLine size={32} />
                  <p className="text">Telegrem</p>
                </div>
                <IoIosArrowForward className="icon" size={24} />
              </div>
            </a>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Support;

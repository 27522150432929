import React from 'react'
import './servises-slick-detail.css'

export default function ServisesSlickDetail() {
    return (
        <div className='servises_slick_detail'>
            <div>
                <h3>Товары с быстрой доставкой </h3>
                <p>из Кыргызстана</p>
            </div>
            <div>
                <img src="https://s3-alpha-sig.figma.com/img/3319/8932/b12f652173c0d6fa5394372486eeb28f?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=M~RVAaKGQiKjTkb9c-U67Ldmp2Ge6GKPeNeYEYfXt4JklWIiiiFqzL3yqReR5FtScAbkpp8cdXl7OKEb5sx3XNR09Sl2A0f7ZpVHwGpKQendDR7l3YSqb2YH1GqUtr6Onub69xSM6izpghHVSEdOgJkFHatwOnev9wEoX1-2WiwRLI4bRh3UEIobr2PVpiP2mk5pnjbBi6MUBxU0o2cLwqc4HLzjHWa4l2-TDjq4gL4rwcKdbGJ3wFNRnCWdU5Jdsrqgx-GmwzXg9Vil33WASGL~2MeSAKbMKdqwVJbubigag-84CfMr5O4P~Go9gTOcYk1AqRBb91OdA~sJDpjzxQ__" alt="" />
            </div>
        </div>
    )
}

import React from 'react'
import './partners-card.scss'

export default function PartnersCard({ img }) {
    return (
        <div className='partners-card'>
            <img src={img} alt="" />
        </div>
    )
}

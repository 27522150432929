import React from 'react'
import './features-card.scss'

export default function FeaturesCard({ icon, title, description }) {
    return (
        <div className='features-card'>
            <div>
                {
                    typeof icon === 'string' ? (
                        <img src={icon} alt="" />
                    ) : (
                       <span>{icon}</span>
                    )
                }
                <h3>{title}</h3>
            </div>
            <p>{description}</p>
        </div>
    )
}

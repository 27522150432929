import React, { useEffect, useState } from 'react';
import { api } from '../../../Api';
import './2mfa.css';
import { useNavigate } from 'react-router-dom';

export default function Mfa() {
    const [mfa, setMfa] = useState({});
    const navigate = useNavigate();

    const handleToggle = async () => {
        try {
            const response = await api.get('/auth/enable-mfa/');
            setMfa(response.data);
            localStorage.setItem('mfa', JSON.stringify(response.data.secret));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleToggle();
    }, []);

    return (
        <div className="mfa">
            <h1>Двухфакторная аутентификация</h1>
            <p>Для использования двухфакторной аутентификации, вам необходимо добавить второй фактор.</p>
            {mfa.qr_code && (
                <div
                    className="qr-code"
                    dangerouslySetInnerHTML={{ __html: mfa.qr_code }}
                />
            )}
            {mfa.secret && (
                <p className="secret-key">Секретный ключ: {mfa.secret}</p>
            )}

            <button className="button" onClick={() => navigate('/secret-code')}>Включить</button>
        </div>
    );
}

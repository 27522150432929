import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import './qr-details.css'
import coin from '../../../../../views/coins/logo.png'
import { RiQrScan2Line } from "react-icons/ri";
import { FiChevronLeft } from "react-icons/fi";
import { api } from '../../../../../Api';
import LoadingAnimate from '../../../../../UI-kit/loading';
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserData } from '../../../../../App/slice/user-info'
import { getProcess } from '../../../../../App/slice/process'

export default function QrDetails() {

    const [isID, setIsID] = useState("")
    const user_coin = useSelector(state => state.user_info.user_info)
    const dispatch = useDispatch()
    const [coin_count, setCoinCount] = useState("")
    const [isUser, setIsUser] = useState(false)
    const [loading, setLoading] = useState(false)
    const [massageResponse, setMassageResponse] = useState("")
    const [showModal, setShowModal] = useState(false);
    const { id } = useParams()
    const [isBalance, setIsBalance] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (isID === user_coin.id) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [isID, user_coin.id]);

    const handleCloseModal = () => {
        setShowModal(false);
        setIsID('')
    };


    useEffect(() => {
        if (id) {
            setIsID(id)
        }
    }, [])

    useEffect(() => {
        dispatch(fetchUserData())
    }, [dispatch])

    useEffect(() => {
        parseFloat(coin_count) > (user_coin.balance || 0)
            ? setIsBalance(true) : setIsBalance(false)
    }, [coin_count])

    const transition = async () => {
        setLoading(true)
        try {
            const response = await api.post('/payment/scanner/?type=1', {
                recipient: isID,
                amount: parseFloat(coin_count),
            })
            if (!response.data.response) {
                setIsUser(true)
                setLoading(false)
                setMassageResponse(response.data.message)
                return
            } else {
                setLoading(false)
                setCoinCount("")
                setIsID("")
                navigate('/success-payments')
                dispatch(getProcess(coin_count))
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='contain-details-all'>
            <div>
                <FiChevronLeft onClick={() => navigate('/')} size={36} />
                <span>Переводы</span>
            </div>
            <div className='contain-details'>
                <p>Перевод возможен только между платформами NEO Fund</p>
                <div className='coin-perevod' >
                    <img src={coin} alt="" className='coin-perevod_img' />
                </div>
                <div>
                    <div className='contain-payblock'>
                        <h1 className='balance-perevod'>Введите ID или отсканируйте QR-code</h1>
                        <div>
                            <h1 className='text-main-perevod'>ID получателя:</h1>
                            {isUser && <span className='error-text-user'>{massageResponse}</span>}
                            <div>
                                <input required value={isID} onChange={(e) => setIsID(e.target.value)} type="text" />
                            </div>
                        </div>
                    </div>
                    <div className='contain-payblock'>
                        <h1 className='balance-perevod'> Ваш текущий баланс: {user_coin.balance || 0}</h1>
                        <div>
                            <h1 className='text-main-perevod'>Сумма:</h1>
                            <input required type="number" value={coin_count} onChange={(e) => setCoinCount(e.target.value)} />
                        </div>
                        {isBalance ? <span className='error-text-perevod'>недостаточно средств</span> : ""}
                    </div>
                    <div className='bottom-btn-perevod'>
                        <button onClick={() => navigate('/qr-scanner')} className='button-scan'>Сканнер <RiQrScan2Line size={32} /></button>
                        <button style={{ background: loading ? '#f84b3b' : "linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%)" }} disabled={loading} onClick={() => transition()} className='button-send-perevod'>
                            {loading ? <LoadingAnimate /> : <div>Отправить</div>}
                        </button>
                    </div>
                </div>
                {showModal && (
                    <div className="modalsss">
                        <div className="modal-contentsss">
                            <span className="closesss" onClick={handleCloseModal}>&times;</span>
                            <p className="modal-messagesss">Нельзя переводить самому себе</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
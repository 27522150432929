import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../Api'

const notification = createSlice({
    name: 'notifications',
    initialState: {
        notification: [],
        news: []
    },
    reducers: {
        getNotif: (state, action) => {
            state.notification = action.payload
        },
        getNews: (state, action) => {
            state.news = action.payload
        }
    }
})

export const { getNotif, getNews } = notification.actions
export default notification.reducer


export const fetchNotifData = () => {
    return async (dispatch) => {
        try {
            const response = await api.get("payment/notifications/");
            dispatch(getNotif(response.data))
        } catch (error) {
            console.log(error);
        }
    };
};

export const fetchNotifNews = () => {
    return async (dispatch) => {
        try {
            const response = await api.get("/news/");
            dispatch(getNews(response.data))
        } catch (error) {
            console.log(error);
        }
    };
};
import React from 'react'
import Header from '../../containers/header/header'
import './birzha.css'

function Birzha() {
  return (
    <div className="birzha">
        <Header />
        <div className='content'>
            <h2>Данный раздел находится в разработке</h2>
        </div>
    </div>
  )
}

export default Birzha
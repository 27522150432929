import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './vvod-asman.css';
import coin from '../../../views/coins/logo.png';
import { HiMiniArrowDownTray } from "react-icons/hi2";
import { FiChevronLeft } from "react-icons/fi";
import { api } from '../../../Api';
import { useDispatch, useSelector } from 'react-redux'
import { getProcess } from '../../../App/slice/process'
import LoadingAnimate from '../../../UI-kit/loading';
import { fetchStatusData } from '../../../App/slice/status';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IoCopyOutline } from "react-icons/io5";

export default function VvodAsman() {
    const [scrin, setscrin] = useState(null);
    const [loading, setLoading] = useState(false)
    const [load_scrin, setLoadScrin] = useState(false)
    const [copied, setCopied] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [fileName, setFileName] = useState("Выбрать файл");


    useEffect(() => {
        dispatch(fetchStatusData())
    }, [dispatch])

    const getStatus = useSelector(state => state.status.status)

    const sendImage = (event) => {
        const imageFile = event.target.files && event.target.files[0];
        setscrin(imageFile);
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const buyAsmanSend = async () => {
        setLoading(true)
        try {
            const token = localStorage.getItem('token');
            const formatData = new FormData();
            formatData.append('img', scrin);

            const response = await api.post('/payment/buy-asman/', formatData, {
                headers: {
                    Authorization: `Token ${token}`
                }
            });
            if (response.data.response) {
                dispatch(getProcess('Ожидание'))
                navigate('/waiting')
                setLoading(false)
            } else {
                setLoading(false)
                setLoadScrin(true)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    function truncateText(text, maxLength) {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    }

    return (
        <div className='contain-buyasman-all'>
            <div>
                <FiChevronLeft onClick={() => navigate('/')} size={36} />
                <span>Пополнение</span>
            </div>
            <div className='contain-buyasman'>
                <p>Пополнение NEO Coin через валюту NEO Coin</p>
                <div className='coin-buyasman' >
                    <img src={coin} alt="" className='coin-buyasman_img' />
                </div>
                <div>
                    <div className='buyasman-payblock'>
                        {copied && <p style={{
                            color: 'green',
                            textAlign: 'center',
                            position: 'absolute',
                            top: '5px',
                            left: '150px',
                            fontSize: '12px'
                        }}>Скопирован</p>}
                        <h1 className='balance-perevod'>Адрес кошелька NEO Coin:</h1>
                        <div>
                            <h2 className='payment-usdt-block'>
                                {Array.isArray(getStatus.asman)
                                    ? truncateText(getStatus.asman.join(', '), 35)
                                    : truncateText(String(getStatus.asman), 35)}
                            </h2>
                            <div>
                                <CopyToClipboard text={getStatus.usdt} onCopy={handleCopy}>
                                    <IoCopyOutline className='' size={24} />
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                    <div className='second-buyasman-payblock'>
                        <h1 className='balance-buyasman'>Прикрепите скриншот вашей транзакции</h1>
                        {load_scrin ? <span className='error-text-buyasman'>скриншот не приклеплен</span> : ""}
                        <div>
                            <label className="custom-file-upload">
                                {fileName} <HiMiniArrowDownTray size={16} />
                                <input
                                    className="input_form_buyasman"
                                    type="file"
                                    accept="image/*"
                                    onChange={sendImage}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='bottom-btn-buyasman'>
                        <button style={{ background: loading ? '#f84b3b' : "linear-gradient(180deg, #E22717 48.4%, #FFAEA7 100%)" }} disabled={loading} onClick={buyAsmanSend} className='button-send-perevod'>
                            {loading ? <LoadingAnimate /> : <div>Купить</div>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React from 'react'
import './advantages.scss'

export default function Advantages({ title, description }) {
    return (
        <div className='advantages-card'>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    )
}

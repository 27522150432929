import React, { useState } from 'react'
import './balance.css'
import { AiFillQuestionCircle } from "react-icons/ai";
import Modal from '../../../../containers/UI/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import vvod from '../../../../views/coins/vvod.png'
import vyvod from '../../../../views/coins/vyvod.png'
import perevod from '../../../../views/coins/perevod.png'
import kupit from '../../../../views/coins/kupit.png'

export default function Balance({ balance, info }) {

    const [modal, setModal] = useState(false)
    const navigate = useNavigate()

    return (
        <div className='balance-card'>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>Ваш баланс</h1>
                    <AiFillQuestionCircle onClick={() => setModal(!modal)} /></div>
                <h2>{balance || 0} <span className='text-balance'> NEO</span> <span className='text-balance-coin'>Coin</span></h2>
                <div className='button-payment_home'>
                    <button onClick={() => navigate('/vvod-asman')}> <div><img src={vvod} alt="" /></div><span>Пополнение</span></button>
                    <button onClick={() => navigate('/drawal-with')}> <div><img src={vyvod} alt="" /></div><span>Вывод</span></button>
                    <button onClick={() => navigate('/qr-details')}> <div><img src={perevod} alt="" /></div><span>Переводы</span></button>
                    <button className='asman_coin_button' onClick={() => navigate('/buy-asman')}><div><img src={kupit} alt="" /></div><span>Купить</span></button>
                </div>
            </div>
            <div>
                {
                    modal && <Modal setIsModalOpen={setModal} color="white">
                        <div style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "20px",
                            color: "black"
                        }}>
                            <p dangerouslySetInnerHTML={{ __html: info }} />
                        </div>
                    </Modal>
                }
            </div>
        </div>
    )
}

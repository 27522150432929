import React, { useEffect, useState } from "react";
import "./settings.css";
import arrow from "../../../views/coins/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Modal from "../../../containers/UI/Modal/Modal";
import { FaCheck } from "react-icons/fa6";
import flag1 from '../../../views/profile/flag1.svg';
import flag2 from "../../../views/profile/flag2.png";
import flag3 from "../../../views/profile/flag3.svg";
import { Switch } from "antd";
import ConfirmationModal from './confirmModal'
import { api } from '../../../Api'
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../../App/slice/user-info";
import { FiChevronLeft } from "react-icons/fi";

const Settings = () => {
  const [data, setData] = useState([
    {
      img: flag1,
      text: "Кыргызча",
      state: false,
    },
    {
      img: flag2,
      text: "Русский",
      state: true,
    },
    {
      img: flag3,
      text: "English",
      state: false,
    },
  ]);
  const [modal, setModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("Русский");
  const [isToggled, setIsToggled] = useState(false);
  const [isToggled2, setIsToggled2] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_info = useSelector(state => state.user_info.user_info);

  useEffect(() => {
    dispatch(fetchUserData())
  }, [dispatch, user_info.is_mfa])

  useEffect(() => {
    if (!user_info.is_mfa) {
      setIsToggled2(false);
    } else {
      setIsToggled2(true);
    }
  }, [user_info.mfa, user_info, dispatch]);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };



  const handleToggle2 = () => {
    setConfirmationModal(true);
  };

  const confirmToggle2FA = async (confirm) => {
    try {
      if (confirm) {
        setIsToggled2(!isToggled2);
      }
      if (!user_info.is_mfa) {
        setConfirmationModal(false);
        const response = await api.get('/auth/enable-mfa');
        localStorage.setItem('mfa', JSON.stringify(response.data.secret));
        navigate('/mfa')
      } else if (user_info.is_mfa) {
        setConfirmationModal(false);
        navigate('/secret-code')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLanguageSelection = (index) => {
    const updatedData = [...data];
    updatedData.forEach((item, i) => {
      item.state = i === index;
    });
    setData(updatedData);
    setSelectedLanguage(data[index].text);
  };

  return (
    <div className="settings">
      <div className="head">
        <FiChevronLeft color='#fff' onClick={() => navigate(-1)} size={36} />
        <span>Настройки</span>
      </div>
      <div>
        <div className="settings_block">
          <div onClick={() => navigate("/change-data")} className="box">
            <p className="text">Изменить данные</p>
            <div className="flex">
              <IoIosArrowForward className="icon" />
            </div>
          </div>
          <div onClick={() => setModal(true)} className="box mer">
            <p className="text">Язык</p>
            <div className="flex">
              <span>{selectedLanguage}</span>
              <IoIosArrowForward className="icon" />
            </div>
          </div>
          <div onClick={() => navigate("/change-password")} className="box">
            <p className="text">Пароль</p>
            <div className="flex">
              <span>Изменить</span>
              <IoIosArrowForward className="icon" />
            </div>
          </div>
          <div onClick={handleToggle} className="box">
            <p className="text">Вход по биометрии</p>
            <div>
              <Switch
                checked={isToggled}
                onChange={handleToggle}
                className={`custom-switch ${isToggled ? "enabled" : "disabled"}`}
              />
            </div>
          </div>
          <div onClick={handleToggle2} className="box">
            <p className="text">Двухфакторная аутентификация</p>
            <div>
              <Switch
                checked={isToggled2}
                onChange={handleToggle2}
                className={`custom-switch ${isToggled2 ? "enabled" : "disabled"}`}
              />
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <Modal setIsModalOpen={setModal}>
          <div className="leng">
            <h2>Выберите язык</h2>
            {data.map((el, index) => (
              <div
                key={index}
                onClick={() => handleLanguageSelection(index)}
                className="box"
              >
                <div className="flex">
                  <div>
                    <img src={el.img} alt="" />
                  </div>
                  <p className="text">{el.text}</p>
                </div>
                {el.state && <FaCheck size={24} />}
              </div>
            ))}
          </div>
        </Modal>
      )}
      {confirmationModal && (
        <ConfirmationModal
          mfa={isToggled2}
          onConfirm={() => confirmToggle2FA(true)}
          onCancel={() => setConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default Settings;

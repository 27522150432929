import { useEffect, useState } from "react";
import { api } from "../../Api";
import MessageCard from "./components/messageCard/messageCard";
import './messages.css';
import '../../containers/header/header.css';
import { Skeleton } from "@mui/material";
import { LiaTelegram } from "react-icons/lia";
import { IoIosNotificationsOutline, IoIosMenu, IoIosClose, IoIosSearch } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Messages() {
    const [messagebox, setMessagebox] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [openMenu, setOpenMenu] = useState(false)
    const [isClosing, setIsClosing] = useState(false);
    const [search, setSearch] = useState(false)

    const toggleSearch = () => {
        setSearch(!search)
    }

    const toggleMenu = () => {
        if (openMenu) {
            setIsClosing(true);
            setTimeout(() => {
                setOpenMenu(!openMenu)
                setIsClosing(false);
            }, 900)
        } else {
            setOpenMenu(!openMenu)
        }
    }

    const names = useSelector((state) =>
        state.user_info.user_info);
    const news = useSelector((state) => state.notification.news);
    const newsLength = news.filter(el => el.is_read === false)
    const sumnotifications = names.notifications + newsLength.length
    const navigate = useNavigate()

    const getMessages = async () => {
        try {
            const response = await api.get("/chat/my-inbox/");
            setMessagebox(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getMessages();
    }, []);

    useEffect(() => {
        setFilteredMessages(
            messagebox.filter(message =>
                message.sender_profile.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                message.sender_profile.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                message.receiver_profile.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                message.receiver_profile.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                message.message.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, messagebox]);

    return (
        <>
            <div className="contain-header">
                {openMenu && <div className="menu_bg" onClick={toggleMenu}></div>}
                <div className="header">
                    <div className="profile-name" onClick={() => navigate('/profile')}>
                        {names.profile_photo ? (
                            <img
                                className="profile-photo-header"
                                src={names.profile_photo}
                                alt=""
                            />
                        ) : (
                            <Skeleton sx={{ bgcolor: 'grey.900' }} width={50} height={50} variant="circular" />
                        )}
                        {search ? '' : <h1>{names.first_name || <Skeleton sx={{ bgcolor: 'grey.900' }} width={70} height={10} variant="text" />}</h1>}
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <IoIosSearch onClick={toggleSearch} size={25} style={search ? { display: "none" } : { cursor: "pointer" }} />
                        {search &&
                            <div className="search-bar">
                                <IoIosSearch size={20} onClick={toggleSearch} style={{ cursor: "pointer" }} />
                                <input
                                    type="text"
                                    placeholder="Поиск чатов..."
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                            </div>
                        }
                        <NavLink target="_blank" to='https://t.me/neocoin_store' style={search ? { display: "none" } : {}}><LiaTelegram size={25} /></NavLink>
                        <div onClick={() => navigate('/natification-page')} style={search ? { display: "none" } : { margin: '5px', position: "relative", cursor: "pointer" }}>
                            {sumnotifications > 0 ? <div className="count_notification">{sumnotifications}</div> : ""}
                            <IoIosNotificationsOutline size={25} />
                        </div>
                        <IoIosMenu size={25} onClick={toggleMenu} style={{ cursor: "pointer" }} />
                    </div>
                    {openMenu && (
                        <div className={`menu ${isClosing ? "closing" : ""}`}>
                            <div className="menu_close">
                                <IoIosClose size={30} onClick={toggleMenu} style={{ cursor: "pointer" }} />
                            </div>
                            <ul>
                                <li><NavLink to='#' onClick={toggleMenu}>Платежи</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>Видео</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>Новости</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>Такси</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>Авиабилеты</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>Фонд</NavLink></li>
                                <li><NavLink to='/discount' onClick={toggleMenu}>Скидки</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>Наши объекты</NavLink></li>
                                <li><NavLink to='/store' onClick={toggleMenu}>neocoin криптовалюта</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>О нас</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>Знакомство</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>Голосования</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Express"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Logy"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Med"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Energy"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Life"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Bank"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Center"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Industry"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Coin"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Milk"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Textile"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Vitamins"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Lab"</NavLink></li>
                                <li><NavLink to='#' onClick={toggleMenu}>ООО "NEO Food"</NavLink></li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className="messages-page">
                <div className="message-container">
                    {filteredMessages.map((message, index) => (
                        <MessageCard key={index} message={message} />
                    ))}
                </div>
                <div style={{ height: '100px', width: '100%' }}></div>
            </div>
        </>
    );
}

import React, { useEffect, useRef } from 'react';
import './notification.css';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifNews } from '../../App/slice/notification';
import './news-notification.css';
import logo from '../../views/coins/logo.png';

export default function NewsNotifications() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notification = useSelector(state => state.notification.news);

    useEffect(() => {
        dispatch(fetchNotifNews());
    }, [dispatch]);

    return (
        <div className='notifictaion_container'>
            <div>
                <FiChevronLeft color='#fff' onClick={() => navigate('/natification-page')} size={36} />
                <span>Новости</span>
            </div>
            <div className='notification_list'>
                {
                    notification.map((el, index) => {
                        return (
                            <div key={index} style={el.type === 1 ? { alignItems: "end" } : { alignItems: "start" }} className='item_notification'>
                                <div style={el.type === 1 ? { flexDirection: "row-reverse" } : { flexDirection: "row" }}>
                                    <div>
                                        <div>
                                            <h1 style={{ color: '#e22717', marginBottom: '8px', fontWeight: '600', fontSize: '12px' }}>Поддержка клиентов NEO Coin</h1>
                                            <div dangerouslySetInnerHTML={{ __html: el.message }} />
                                            <h2 className='notif_time'>{el.date}</h2>
                                        </div>
                                        <img src={logo} alt="" style={{border: 'none'}} className='notif_img' />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

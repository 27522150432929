import React, { useEffect, useState } from "react";
import "./discount.css";
import Header from "../../containers/header/header";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cate1 from "../../views/disc/standart.png";
import cate2 from "../../views/disc/bronze.png";
import cate3 from "../../views/disc/silver.png";
import cate4 from "../../views/disc/gold.png";
import cate5 from "../../views/disc/vip.png";
import SkeletonDiscount from "./Skeleton";
import { fetchdiscountData } from "../../App/slice/discount";
import { IoEyeSharp } from "react-icons/io5";

const categoryImages = [cate1, cate2, cate3, cate4, cate5];

export default function Discount() {
  const names = useSelector((state) => state.user_info.user_info);
  const data = useSelector(state => state.discount.discount)
  const [cate, setCate] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchdiscountData())
  }, [])

  useEffect(() => {
    if (names) {
      setCate(names.status);
    }
  }, [names]);

  return (
    <div className="discount">
      <Header />
      <div className="container_2">
        <div>
          <h1 className="title">Скидки</h1>
          <p className="text_dis">
            Нажмите на статус, чтобы узнать, какие привилегии есть у выбранного статуса
          </p>
        </div>
        <div className="cateing">
          {["NEO 4", "NEO 3", "NEO 2", "NEO 1", "NEO"].map((category, index) => (
            <div
              key={index}
              onClick={() => setCate(category)}
              className={`cate ${cate === category && "active"}`}
            >
              <img width={45} height={45} src={categoryImages[index]} alt="" />
              <h1>{category}</h1>
            </div>
          ))}
        </div>
        {data.length ? (data.map((el, key) => (
          el.partners.length > 0 ? <div key={key}>
            <h2>{el.name}</h2>
            <div className="discount_block">
              {el.partners.map((item, idx) => (
                <div key={idx}>
                  <div
                    onClick={() => navigate(`/discount-detail/${item.id}`)}
                    className="discount_box"
                  >
                    <img src={`https://neocoin.io${item.img}`} alt="" />
                  </div>
                  <p className="text_discount">{item.title}</p>
                  {cate === "NEO-4" && <p className="absolute">{item.d_standard} % скидки</p>}
                  {cate === "NEO-3" && <p className="absolute">{item.d_bronze} % скидки</p>}
                  {cate === "NEO-2" && <p className="absolute">{item.d_silver} % скидки</p>}
                  {cate === "NEO-1" && <p className="absolute">{item.d_gold} % скидки</p>}
                  {cate === "NEO" && <p className="absolute">{item.d_vip} % скидки</p>}
                  <h2 className="cost_of_visit">{item.cost_of_visit} NEO Coin</h2>
                  <h5><IoEyeSharp /> <span> {item.views}</span></h5>
                </div>
              ))}
            </div>
          </div> : ""
        ))
        ) : <SkeletonDiscount />}
      </div>
    </div>
  );
}

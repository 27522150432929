import React, { useState } from "react";
import "./sign-in.css";
import logo from "../../../views/coins/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { api } from "../../../Api";
import LoadingAnimate from "../../../UI-kit/loading";
import axios from "axios";

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [view, setView] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://neocoin.io/api/auth/login/", {
        email,
        password,
      });
      if (response.data.response === true) {
        const { token } = response.data;
        localStorage.setItem('accessToken', token);
        navigate("/");
      } else if (response.data.response === false && response.data.is_credentials_valid === true) {
        localStorage.setItem('email', email)
        localStorage.setItem('password', password)
        navigate('/mfa-code')
      }
      else {
        if (response.data.message) {
          alert(response.data.message, "error");
        }
        if (response.data.isactivated === false) {
          alert(response.data.message, "error");
          navigate("/send-code");
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <div className="container-sign-in">
        <div className="images-sign-in">
          <div className="coin-sign-in">
            <img src={logo} alt="" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="submit-sign-in">
              <h1>Вход</h1>
              <div className="inputs-sign-in">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                  required
                  autoComplete="off"
                />
                <div className="password-sign-in">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    maxLength="20"
                    type={view ? "text" : "password"}
                    placeholder="Пароль"
                    required
                    autoComplete="off"
                  />
                  <span className="span-icon" onClick={() => setView(!view)}>
                    {view ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                <NavLink to="/forgot-password" className="link">
                  Забыли пароль?
                </NavLink>
                <button style={{ background: loading ? '#f1301e' : "#E22717", display: 'flex', justifyContent: 'center', alignItems: 'center' }} disabled={loading} onSubmit={handleSubmit} className="sign-in">
                  {loading ? <LoadingAnimate /> : "Войти"}
                </button>
              </div>
            </div>
          </form>
          <p className="footer-text">
            Нет аккаунта?
            <NavLink to={"/register"}>
              Зарегистрируйтесь
            </NavLink>
          </p>
          <p style={{ textAlign: 'center', display: "grid", placeItems: "center", marginTop: '15px', color: '#1A1A1A', fontSize: '14px', fontWeight: '500' }}>
            Не получили код подтверждение?
            <NavLink to={"/reserve-code"}style={{ color: '#E22717' }}>
              Получить
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
}
